import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-terms',
  templateUrl: './legal-terms.component.html',
  styleUrls: ['./legal-terms.component.scss']
})
export class LegalTermsComponent {

}
