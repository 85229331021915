import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sorteio-ativo-card',
  templateUrl: './sorteio-ativo-card.component.html',
  styleUrls: ['./sorteio-ativo-card.component.scss']
})
export class SorteioAtivoCardComponent implements OnInit{

  @Input() backgroundImage: any;
  @Input() cardRightTitle: string = '';
  @Input() cardRightSubtitle: string = '';
  @Input() isActive: boolean = false;
  @Input() date: any;

  drawDate: Date = new Date;
  differenceTime: number = 0;
  restingTime: string = '';

  constructor() { }

  ngOnInit() {
    this.drawDate = new Date(this.date);
    setInterval(() => {
      let difference = this.drawDate.getTime() - new Date().getTime();
      this.differenceTime = difference;
      this.restingTime = this.formatTime(this.differenceTime);
    }, 1000);
  }

  formatTime(differenceTime: number): string {
    let seconds = Math.floor((differenceTime / 1000) % 60);
    let minuts = Math.floor((differenceTime / (1000 * 60)) % 60);
    let hours = Math.floor((differenceTime / (1000 * 60 * 60)) % 24);
    let days = Math.floor((differenceTime / (1000 * 60 *60 * 24)));
    return `${days.toString().padStart(2, '0')}d ${hours.toString().padStart(2, '0')}h ${minuts.toString().padStart(2, '0')}min ${seconds.toString().padStart(2, '0')}s`;
  }
}
