import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';

import { DateService } from 'src/app/core/services/date-service/date.service';

@Component({
  selector: 'app-checkout-draw-primary-card',
  templateUrl: './checkout-draw-primary-card.component.html',
  styleUrls: ['./checkout-draw-primary-card.component.scss']
})
export class CheckoutDrawPrimaryCardComponent implements OnInit{

  @Output() takeCheckoutForm: EventEmitter<any> = new EventEmitter<any>();

  @Input() cardTitle: string = '';
  @Input() quantity: number = 0;
  @Input() unitPrice: number = 0;
  @Input() total: number = 0;
  @Input() image: any;
  @Input() packagesToShow: any[] = [];
  @Input() freeTicket: number = 0;

  checkoutDataForm: FormGroup = this.fb.group({});
  
  isInputFocused: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dateService: DateService,
    private authService: AuthService,
  ) {
    this.startForm();
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.loadUserDataAuth();
    }
  }

  get checkoutForm (): void {
    return this.checkoutDataForm.value;
  }

  updateFormToCheckoutPage() {
    this.takeCheckoutForm.emit(this.checkoutDataForm.value);
  }

  onChange(event: any) {
    try {
      const dataConvertida = this.dateService.parseDate(event.target.value);
      this.checkoutDataForm.get('dataNascimento')?.setValue(dataConvertida);
      this.updateFormToCheckoutPage();
    } catch (error) {
      console.error('Invalid date format', error);
    }
  }

  backToDraw() {
    window.history.back();
  }

  private startForm(): void {
    this.checkoutDataForm = this.fb.group({
      nome: [null, [Validators.required]],
      telefone: [null],
      email: [null],
      cpf: [null],
      dataNascimento: [''],
      enderecoCep: [null],
      enderecoNumero: [null],
    });
  }

  private loadUserDataAuth() {
    this.checkoutDataForm.get('nome')?.setValue(this.authService.userName());
    this.checkoutDataForm.get('telefone')?.setValue(this.authService.userPhone());
    this.checkoutDataForm.get('cpf')?.setValue(this.authService.cpfCnpjUser());
    this.checkoutDataForm.get('email')?.setValue(this.authService.userEmail());
    /* const userName = this.authService.userName().split(' ');
    this.checkoutDataForm.get('nome')?.setValue(userName.shift() || '');
    this.checkoutDataForm.get('sobrenome')?.setValue(userName.join(' ')); */
  }
}
