import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DataService } from 'src/app/core/services/data-service/data.service';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-user-link-auth',
  templateUrl: './user-link-auth.component.html',
  styleUrls: ['./user-link-auth.component.scss']
})
export class UserLinkAuthComponent implements OnInit{

  initialLoading: boolean = true;

  private userEmail: string = '';
  private cpfUser: string = '';

  constructor(
    private dataService: DataService,
    private enterpriseService: EnterpriseService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.userEmail = this.route.snapshot.params['email'];
    this.cpfUser = this.route.snapshot.params['cpf'];
    const mainUrl = `${window.location.hostname}`;
    if (mainUrl === 'localhost') {
      this.loadEnterpriseData('sorteiodois.sourcefile.com.br');
    } else {
      this.loadEnterpriseData(mainUrl);
    }
  }

  private loadEnterpriseData(domain: string) {
    let payload = { dominio: domain }
    this.enterpriseService.postLoadEnterpriseData(payload).subscribe({
      next: this.treatLoadConfigsDomainSuccessfull,
      error: this.loginByLinkErrorAtAll
    });
  }
  
  private loginUserByLink() {
    let payload = {
      email: this.userEmail,
      cpfCnpj: this.cpfUser,
      idEmpresa: this.dataService.getEnterpriseId()
    };
    this.authService.login(payload).subscribe({
      next: this.loginUserByLinkSuccessfull,
      error: this.loginByLinkErrorAtAll
    });
  }

  private treatLoadConfigsDomainSuccessfull = (res: any): void => {
    this.dataService.setEnterpriseData(res);
    this.loginUserByLink();
  }

  private loginUserByLinkSuccessfull = (res: any): void => {
    if (res.data){
      this.authService.saveUserAccessData({token: res.data});
      this.router.navigate([`/user-settings`]);
      this.initialLoading = false;
    }
  }

  private loginByLinkErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'danger');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
