import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnterpriseEndpointMapper } from '../../mappers/enterprise-endpoint.mapper';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../data-service/data.service';

@Injectable({
  providedIn: 'root'
})
export class BuyService {

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private authService: AuthService
    ) { }

  getBuyClient() {
    const headers = new HttpHeaders({
      empresa: this.dataService.getEnterpriseId(),
      Authorization: `Bearer ${this.authService.getToken()}`
    });
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Buy}/cliente`, options);
  }

  getBuyTicket(orderId: string) {
    const headers = new HttpHeaders({
      empresa: this.dataService.getEnterpriseId(),
      Authorization: `Bearer ${this.authService.getToken()}`
    });
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Buy}/bilhete?idCompra=${orderId}`, options);
  }

  postBuy(payload: any, enterpriseId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.post(`${EnterpriseEndpointMapper.Buy}`, payload, options);
  }
}
