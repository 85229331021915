<mat-card class="center-card">
  <div class="container-draw">
    <div class="column">
      <div class="row-main-card">
        <app-sorteio-ativo-card
          [backgroundImage]="mainBackgroundImage"
          [date]="dateDraw"
          [isActive]="isActive"
          style="max-width: 830px; width: 100%;">
        </app-sorteio-ativo-card>
        <div class="select-image">
          <img *ngIf="activeDraw?.imagens?.length >= 1" [src]="activeImage1" (click)="changeImageInCard(1)" alt="Imagem sorteio 01" />
          <img *ngIf="activeDraw?.imagens?.length >= 2" [src]="activeImage2" (click)="changeImageInCard(2)" alt="Imagem sorteio 02" />
          <img *ngIf="activeDraw?.imagens?.length >= 3" [src]="activeImage3" (click)="changeImageInCard(3)" alt="Imagem sorteio 03" />
        </div>
      </div>
      <div class="navbar">
        <a class="money-bag" href="#">
          + Pacotes de Bilhetes
        </a>
      </div>
      <div class="container">
        <div class="card-container">
          <div class="card" *ngFor="let ticket of tickets; let i = index;" (click)="insertQuantityCardTicket(ticket.quantidadeBilhetes, ticket.valorPacote, ticket.id)">
            <div class="chance">
              <span>Aumente suas chances</span>
            </div>
            <h1>+{{ ticket.quantidadeBilhetes }} Und.</h1>
            <div class="price">
              <span>R$ {{ ticket.valorPacote.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</span>
              <button class="button-plus">
                  <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="select-ticket">
        <span>Quantidade (Min. {{ buyTicketData?.quantidadeMinimaBilhetes }})</span>
        <div>
          <span>Avulsos: R${{ buyTicketData?.valorBilhete.toLocaleString('pt-br' , {minimumFractionDigits: 2}) }} x</span>
            <div>
              <button
                (click)="mineOneTicket()">
                <mat-icon>remove</mat-icon>
              </button>
              <input
                type="text"
                [value]="freeTickets"
                (change)="updateFreeTickets($event)">
              <button class="button-plus"
                (click)="plusOneTicket()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
        </div>
        <div class="package-container">
          <div class="pkg-info" *ngFor="let item of packagesToShow; let i = index">
            <h4>+{{ item.bilhetes }} Und.</h4>
            <h4>R${{ item.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}) }} x {{ item.quantidade }}</h4>
            <h5>=</h5>
            <h3>R${{ item.valor * item.quantidade }}</h3>
            <mat-icon
              (click)="removeQuantityCardTicket(item.bilhetes, item.valor, item.idPacote)">delete_seep</mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <h3>Total de Bilhetes: {{ countTickets }}</h3>
          <span>R$ {{ priceTickets.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}/Total</span>
        </div>
        <button class="ensure"
          (click)="navigateToTicketDraw()"
          [disabled]="restingTickets">
          <span *ngIf="!restingTickets">Comprar!</span>
          <span *ngIf="restingTickets">Encerrado!</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="card-more-draws">
      <button class="more-draws" (click)="triggerMoreDrawsButton()">
        <span>Conferir Mais Sorteios</span>
      </button>
      <a class="see-regulation" (click)="navigateToLegalTerms()">Ver Regulamento</a>
    </mat-card>
    <div style="margin-left: 12px;"></div>
    <app-card-whatsapp
        [data]="activeDraw"
        [isActive]="true"
        [cardTitle]="activeDraw?.titulo"
        [cardSubtitle]="activeDraw?.descricao"
        (whatsAppButton)="navigateToWhatsapp($event)"
        (telegramButton)="navigateToTelegram($event)"
        (moreDrawsButton)="navigateToAllDraw()">
      </app-card-whatsapp>
  </div>
  <app-table-draw
    [information]="dataToQuoteAward"
    [description]="activeDraw?.descricao">
  </app-table-draw>
</mat-card>
