import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, map } from 'rxjs';
import { EnterpriseEndpointMapper } from '../../mappers/enterprise-endpoint.mapper';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy{

  private readonly USER_DATA: any = 'sharedUserData';
  userDataString: {} = {};
  authSubj: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  private expirationCheckInterval: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
  ) {
    if (this.getUserData()) {
      this.loadLocalAuthData();
      this.startExpirationCheck();
    }
  }

  ngOnDestroy(): void {}

  saveUserAccessData(res: any) {
    localStorage.setItem(this.USER_DATA, JSON.stringify({...res, getTime: new Date().getTime()}));
  }

  getUserData() {
    const userData = localStorage.getItem(this.USER_DATA);
    return userData ? JSON.parse(userData) : null
  }

  userId(): string {
    return this.getUserData()?.token?.tokenUsuario?.id;
  }

  userEmail(): string {
    return this.getUserData()?.token?.tokenUsuario?.email;
  }

  userName(): string {
    return this.getUserData()?.token?.tokenUsuario?.nome;
  }

  userPhone(): string {
    return this.getUserData()?.token?.tokenUsuario?.telefone;
  }

  cpfCnpjUser(): string {
    return this.getUserData()?.token?.tokenUsuario?.cpfCnpj;
  }

  getToken(): string {
    return this.getUserData()?.token?.accessToken;
  }

  getTime(): number {
    return this.getUserData()?.getTime;
  }

  is2FaEnabled(): boolean {
    return this.getUserData()?.token?.eh2Fa;
  }

  isAuthenticated(): boolean {
    return this.getUserData()?.token?.accessToken ? true : false;
  }

  isAuthenticatedAsync(): Observable<boolean> {
    return this.authSubj.asObservable();
  }

  register(res: any): void {
    if (res) {
      this.saveUserAccessData(res);
    }
  }

  login(payload: any): Observable<any> {
    return this.http.post(`${EnterpriseEndpointMapper.Auth}/autenticar-plataforma`, payload);
  }

  logout() {
    localStorage.setItem(this.USER_DATA, JSON.stringify(null));
    this.authSubj.next(false);
    this.router.navigate([`/`]);
  }

  async loadLocalAuthData(): Promise<any> {
    try {
      const result = await this.getUserData();
      this.userDataString = result;
      this.authSubj.next(!!this.USER_DATA?.token?.accessToken);
      return result;
    }
    catch (e) { console.log(e); }
  }

  private startExpirationCheck(): void {
    if (this.isAuthenticated()) {
      const expirationTime = this.getUserData()?.token?.expiresIn;
      if (expirationTime) {
        const expirationTimestamp = this.getTime() + (expirationTime * 1000);
        const now = Date.now();
        if (expirationTimestamp < now) {
          this.logout();
          this.toastService.showToast('Acesso Expirado. Faça Login Novamente', 'warning');
        }
      }
    }
  }
}
