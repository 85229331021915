import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private readonly ENTERPRISE_KEY: any = 'sharedEnterprise';
  private readonly SWEEPSTAKES_KEY: any = 'sharedSweepstakes';
  titleFromDatabase: string = '';

  constructor() { }

  setSweepstakesData(data: any) {
    localStorage.setItem(this.SWEEPSTAKES_KEY, JSON.stringify(data));
  }

  setEnterpriseData(data: any) {
    localStorage.setItem(this.ENTERPRISE_KEY, JSON.stringify(data));
  }

  setTitleFromDatabase(title: string) {
    this.titleFromDatabase = title;
  }

  getEnterpriseData() {
    const enterpriseData = localStorage.getItem(this.ENTERPRISE_KEY);
    return enterpriseData ? JSON.parse(enterpriseData) : null;
  }

  getSweepstakesData() {
    const sweepstakesData = localStorage.getItem(this.SWEEPSTAKES_KEY);
    return sweepstakesData ? JSON.parse(sweepstakesData) : null;
  }

  getEnterpriseId(): string {
    return this.getEnterpriseData()?.id;
  }

  getTitleFromDatabase() {
    return this.titleFromDatabase;
  }
}
