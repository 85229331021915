import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

import { EnterpriseEndpointMapper } from '../../mappers/enterprise-endpoint.mapper';
import { Mock } from '../mocks';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {

  constructor(private http: HttpClient) { }

  postLoadEnterpriseData(payload: any):Observable<any> {
    return this.http.post(`${EnterpriseEndpointMapper.Enterprise}/obter-configuracao`, payload);
  }

  getSweepstakes(enterpriseId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Draw}`, options);
  }
  
  getOnlyActiveSweepstakes(enterpriseId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Draw}?ativo=true`, options);
  }
  
  getLastWinners(enterpriseId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Draw}/ultimos-ganhadores`, options);
  }

  getQuestionnaires(enterpriseId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Enterprise}/obter-questionarios`, options);
  }

  getRealizedDraw(drawId: any): Observable<any> {
    return this.http.get(`${EnterpriseEndpointMapper.Enterprise}/obter-sorteio-realizado/${drawId}`);
  }

  getTickets(): Observable<any> {
    return of(Mock.Ticket())
  }

  getDataTable(): Observable<any> {
    return of(Mock.Table())
  }

  getAllDraws(): Observable<any> {
    return of(Mock.Draws())
  }

  getRequests(): Observable<any> {
    return of(Mock.Requests())
  }
}
