<div class="spinner-data" *ngIf="loadingQuestionnaries">
  <mat-progress-spinner
    class="spinner"
    diameter="50"
    color="warn"
    mode="indeterminate">
  </mat-progress-spinner>
</div>

<mat-card class="center-card" *ngIf="!loadingQuestionnaries">
  <mat-card-content>
    <div class="title">
      <h2>FAQ</h2>
      <h3>Perguntas Frequentes</h3>
    </div>
    <div *ngFor="let question of questionnaries; let i = index">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h4>{{ question.textoPergunta }}</h4>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <h5>{{ question.textoResposta }}</h5>
      </mat-expansion-panel>
    </div>
    <mat-card class="no-questionnaries" *ngIf="questionnaries.length === 0">
      <h3>Sem Perguntas e Respostas no Momento</h3>
    </mat-card>
  </mat-card-content>
</mat-card>
