import { Component, Input,  OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-orders',
  templateUrl: './latest-orders.component.html',
  styleUrls: ['./latest-orders.component.scss']
})
export class LatestOrdersComponent implements OnInit{

  @Input() backgroundImage: any;
  @Input() drawName: string = '';
  @Input() drawCode: string = '';
  @Input() statusPayment: string = '';

  constructor () {}

  ngOnInit() {

  }
}
