<div class="main-card" [ngStyle]="{'background': backgroundImage}">
  <div class="column">
    <h4>{{ data?.titulo }}</h4>
    <div class="row">
      <div class="inside-left-card">
        <div class="card-content">
          <div>
            <span>Acaba em:</span>
            <h1>{{ restingTime }}</h1>
            <button mat-button class="take-button"
              (click)="navigateToActiveDraw()">
              <h3>Quero Garantir o Meu!</h3>
            </button>
          </div>
        </div>
      </div>
      <div class="inside-right-card">
        <div>
          <div class="column">
            <h2>Apenas</h2>
            <h2 class="font-36-weight-600">R$ {{ data?.valorBilhete.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) }}</h2>
            <div class="div-card">
              <h3 class="weight-400">Ver Regulamento</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
