<mat-card class="main-card" *ngIf="data"
  [ngStyle]="{'background': linearGradient}"
  (click)="triggerToDraw($event)">
  <mat-card-content>
    <mat-card class="title-card">
      <h2>{{ data?.titulo }}</h2>
    </mat-card>
    <mat-card class="description-card">
      <h3 class="-subtitle">{{ data?.descricao }}</h3>
    </mat-card>
    <mat-card class="footer-card">
      <h6>{{ resultPercent }}% Vendidos</h6>
      <mat-progress-bar class="mat-progress-bar-fill"
        mode="determinate"
        [value]="resultPercent"
        color="white"></mat-progress-bar>
    </mat-card>
  </mat-card-content>
</mat-card>
