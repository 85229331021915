<mat-card class="center-card">
  <mat-card-content>
    <div class="title">
      <h2>Política de Privacidade</h2>
    </div>
    <div class="define-text">
      <h3>1. Introdução:</h3>
      <h4>A Drawerfy está comprometida com a confidencialidade e proteção dos dados pessoais de seus Usuários, assim como com a transparência em seus valores em relação a seus Usuários, colaboradores, fornecedores e parceiros.</h4>
      <h4>Esta Política de Privacidade aplica-se apenas aos dados pessoais dos Usuários processados pela Drawerfy, os quais são necessários para a prestação dos serviços disponibilizados pela tecnologia da Drawerfy.</h4>
      <h4>Esta Política de Privacidade não abrange as práticas de privacidade e proteção de dados de terceiros. Para políticas aplicáveis aos dados pessoais processados por terceiros parceiros dos Serviços da Drawerfy sugerimos que consulte os respectivos sites.</h4>
    </div>
    <div class="define-text">
      <h3>2. Definições gerais utilizadas:</h3>
      <h4>Ao longo desta Política de Privacidade, o leitor encontrará uma série de palavras ou expressões iniciadas por letra maiúscula. Nesta seção, portanto, trataremos de apresentar o significado destas.</h4>
      <div class="letters-info">
        <h4>A.	"Usuário”: engloba todos aqueles que utilizam o site e os serviços da Drawerfy, seja como Organizador, Colaborador ou mero visitante.</h4>
        <h4>B.	“Anonimização”: técnica pela qual um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo, de modo se torna impossível a reidentificação, mesmo que por soluções técnicas.</h4>
        <h4>C.	“Autoridade Nacional de Proteção de Dados Pessoais (ANPD)”: órgão da administração pública federal que possui atribuições referentes a proteção de dados pessoais e privacidade, incluindo a fiscalização para o cumprimento da LGPD.</h4>
        <h4>D.	“Cookies”: arquivo pequeno, o qual possui uma sequência de caracteres, criado e enviado pelos websites ao computador utilizado para visitá-los. Os cookies auxiliam a lembrar as preferências e personalizar o acesso, permitindo que a navegação seja mais rápida e agradável. Há possibilidade de configurar o navegador para não aceitar cookies, entretanto sem eles alguns recursos ou serviços do site podem ficar comprometidos e limitados.</h4>
        <h4>E.	“Dados Pessoais”: informações que identificam ou que podem identificar o Usuário.</h4>
        <h4>F.	“Dispositivo”: significa o aparelho, seja computadores, tablets ou smartphones, que podem ser utilizados pelo Usuário para acessar a tecnologia da Drawerfy.</h4>
        <h4>G.	“Endereço IP”: número atribuído a cada Dispositivo conectado a tecnologia da Drawerfy, conhecido como endereço de protocolo de Internet (Internet Protocol Address ou IP). Comumente, esses números são atribuídos em blocos geográficos e, assim, um endereço IP pode ser usado para identificar, por exemplo, de qual local um Dispositivo está se conectando à Internet.</h4>
        <h4>H.	“LGPD”: sigla utilizada para identificar a Lei Geral de Proteção de Dados (Lei nº 13.709/2018).</h4>
        <h4>I.	“Plataforma”: espaço de operação da tecnologia da Drawerfy.</h4>
        <h4>J.	“Serviços”: refere-se às funcionalidades da tecnologia oferecida pela Drawerfy.</h4>
        <h4>K.	“Titular dos Dados”: qualquer pessoa física identificada ou identificável a quem se referem os dados pessoais tratados. São, por exemplo, os Usuários, sendo eles Organizadores, Colaboradores e, até mesmo, os visitantes.</h4>
        <h4>L.	“Tratamento”: toda operação que há possibilidade de ser realizada com Dados Pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</h4>
        <h4>M.	“Sites de Terceiros”: links para outros sites e serviços online de terceiros, os quais podem ser acessados por meio de links disponíveis na Plataforma. A utilização de tais sites e serviços está sujeita a termos de uso e política de privacidade de tais terceiros, especialmente de Anunciantes e Parceiros.</h4>
        <h4>N.	“Organizadores”: são os indivíduos que possuem acesso administrativo a uma ou mais Campanhas, bem como aqueles que têm acesso administrativo a uma conta de organização na Drawerfy, assim como, refere-se também à(s) Organização(ões) que esses indivíduos podem representar.</h4>
        <h4>O.	"Campanhas": são todas as iniciativas ou projetos realizados pelos Organizadores cadastrados na plataforma da Drawerfy, com a finalidade de permitir que os Usuários visualizem e colaborem de acordo com seus interesses.</h4>
        <h4>P.	"Contribuições": referem-se a todas as transações, monetárias ou não, realizadas para uma Campanha cadastrada por um Organizador na plataforma da Drawerfy.</h4>
        <h4>Q.	"Colaborador": refere-se a qualquer utilizador que faça uma Contribuição para uma Campanha cadastrada por um Organizador na plataforma da Drawerfy.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>3. Informações coletadas:</h3>
      <h4>A Drawerfy coleta informações pessoais dos Usuários sempre que este usa os Serviços. Algumas são informações que os Usuário fornecem de forma direta, como aquelas essenciais para registrar uma conta ou realizar uma contribuição em alguma das Campanhas. Outras informações são coletadas quando, em específico, os Organizadores utilizam o canal de suporte oferecido pela Drawerfy. </h4>
      <h4>A coleta dos Dados Pessoais é realizada com as seguintes finalidades:</h4>
      <div class="letters-info">
        <h4>A.	para fornecer os serviços disponíveis pela tecnologia da Drawerfy;</h4>
        <h4>B.	para aprimorar e desenvolver os Serviços, e oferecer ao Usuário uma melhor experiência com a Plataforma;</h4>
        <h4>C.	para identificar, autenticar e verificar os requisitos para contratação dos Serviços disponibilizados pela Drawerfy;</h4>
        <h4>D.	para manter contato com os Organizadores, incluindo para atendimento por meio dos canais de suporte e para envio de notificações relativas aos Serviços;</h4>
        <h4>E.	para o efetivo cumprimento das obrigações e requisitos legais e/ou exigidos pelas autoridades competentes;</h4>
        <h4>F.	para detectar, prevenir e solucionar problemas técnicos ou de segurança, fraudes, atos ilícitos, crimes financeiros e crimes relacionados a fim de proteger a segurança dos Usuários e da Plataforma;</h4>
        <h4>G.	para proteger os direitos de Usuários, terceiros ou da própria Drawerfy;</h4>
        <h4>H.	para fazer anúncios e contatos publicitários e promocionais;</h4>
        <h4>I.	para notificar o Usuário sobre eventuais alterações nos Serviços.</h4>
      </div>
      <h4>Do Dispositivo do Usuário, a tecnologia da Drawerfy recebe:</h4>
      <div class="letters-info">
        <h4>A.	as informações relativas ao dispositivo propriamente dito, sendo elas, mas não se limitando ao, endereço de IP, modelo de hardware, sistema operacional e versão, software, idioma de preferência, números de série, informações de movimento do dispositivo, informações da rede móvel e dados de localização;</h4>
        <h4>B.	os logs de servidores, os quais incluem informações como data e hora de acesso, funcionalidades ou páginas do aplicativo que o Usuário visualizou, falhas no aplicativo e outras atividades do sistema, e o site ou serviço de terceiros que o Usuário estava usando imediatamente antes de acessar a Plataforma.</h4>
      </div>
      <h4>Os cookies coletam algumas informações pessoais sobre os Usuários sempre que utilizam a Plataforma. O Usuário poderá escolher se deseja aceitar ou rejeitar alguns ou todos os tipos de Cookies, podendo controlar por meio das configurações do navegador do dispositivo ou no banner de Cookies exibido na Plataforma. Se o Usuário continuar a usar a Plataforma sem ajustar as configurações do seu navegador, a tecnologia da Drawerfy utilizará todos os Cookies necessários para o funcionamento correto da Plataforma e dos Serviços.</h4>
      <h4>Os Dados Pessoais tratados para o cadastro dos Organizadores na Plataforma são nome completo, CPF ou CNPJ, endereço residencial ou comercial, número de telefone e endereço de e-mail. </h4>
      <h4>Para efetivação da Contribuição, os Dados Pessoais tratados consistem em nome completo, endereço de e-mail, CPF ou CNPJ, número de telefone e endereço residencial.</h4>
      <h4>O Usuário poderá ser convidado a habilitar as notificações do navegador, de forma que passe a receber alertas. A qualquer momento, o Usuário pode ativar ou desativar as notificações no navegador, por meio das configurações da sua conta.</h4>
    </div>
    <div class="define-text">
      <h3>4. Compartilhamento de informações:</h3>
      <h4>A Drawerfy poderá compartilhar os Dados Pessoais dos Usuários com terceiros, somente na medida em que se fizer necessária para que as finalidades necessárias sejam atingidas.</h4>
      <h4>No caso do Colaborador, o Organizador da Campanha que aquele tenha participado, terá acesso aos Dados Pessoais informados.</h4>
      <h4>A Drawerfy poderá compartilhar os Dados Pessoais dos Usuários com autoridades competentes e órgãos reguladores, com a finalidade de:</h4>
      <div class="letters-info">
        <h4>A.	cumprir com decisões judiciais ou administrativas;</h4>
        <h4>B.	exercício regular de direitos da Drawerfy, inclusive apresentando documento em processos;</h4>
        <h4>C.	cumprimento de obrigação legal ou regulatória.</h4>
      </div>
      <h4>Os Dados Pessoais tratados poderão ser compartilhados com prestadores de serviços e parceiros de negócios, com a finalidade de execução dos serviços contratados, incluindo:</h4>
      <div class="letters-info">
        <h4>A.	prestadores de serviços técnicos que operam a infraestrutura técnica de que necessita para o fornecimento dos Serviços, em particular fornecedores que guardam, geram e mantêm a Plataforma funcionando;</h4>
        <h4>B.	Instituições Parceiras e/ou prestadores serviço que auxiliam a Drawerfy na gestão de antifraude dos Serviços;</h4>
        <h4>C.	prestadores de serviço que auxiliam a Drawerfy na cobrança de dívidas, na proteção ao crédito e na prevenção de fraudes, incluindo instituições financeiras, instituições de pagamento e outros que forem necessários. </h4>
      </div>
      <h4>Os Dados Pessoais tratados poderão ser compartilhados com terceiros, para viabilizar venda, parcial ou total (inclusive em conexão com falência ou processo semelhante), ou como parte de qualquer reorganização ou reestruturação do negócio, fusão, cisão ou incorporação, de modo que a Drawerfy poderá compartilhar as informações dos Usuários com terceiros que façam parte dos negócios, adotando as medidas necessárias a fim de assegurar o respeito aos direitos de privacidade dos Usuários.</h4>
    </div>
    <div class="define-text">
      <h3>5. Segurança das informações:</h3>
      <h4>Todos os Dados Pessoais Tratados pela Drawerfy são armazenados em um banco de dados reservado e com acesso limitado somente aos funcionários habilitados em funções que requeiram acesso e utilização, nos termos desta Política de Privacidade ou para fins de segurança.</h4>
      <h4>A Drawerfy opera com mecanismos de segurança em conformidade com os melhores padrões e práticas adotados pelo mercado, os quais visam assegurar a privacidade, autenticidade e inviolabilidade das informações.</h4>
      <h4>O Usuário se compromete a adotar procedimentos básicos de segurança em seu celular e/ou computador, por meio da utilização de ferramentas como antivírus, além de não fornecer ou informar sua senha a quaisquer terceiros.</h4>
    </div>
    <div class="define-text">
      <h3>6. Armazenamento das informações coletadas:</h3>
      <h4>A Drawerfy reserva-se no direito de manter os Dados Pessoais enquanto sua a relação com os Usuários perdurar, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes.</h4>
      <h4>Quando os Dados Pessoais deixam de ser necessários para o alcance de determinada finalidade aos Serviços ou ainda não haja mais necessidade legal em seu armazenamento, tais dados serão excluídos ou anonimizados.</h4>
    </div>
    <div class="define-text">
      <h3>7. Vigência e Revisão:</h3>
      <h4>A presente Política de Privacidade está em vigor por tempo indeterminado. A Drawerfy reserva-se no direito de modificar livremente e a qualquer tempo todo e qualquer condição, obrigação ou regramento aqui previsto. </h4>
      <h4>Havendo modificações e/ou atualizações na presente Política de Privacidade, o Usuário será devidamente notificado e, assim, ao continuar usando a Plataforma e os Serviços da Drawerfy, o Usuário estará concordando com todas as alterações e atualizações realizadas. </h4>
    </div>
  </mat-card-content>
</mat-card>
