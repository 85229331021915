<div class="form">
  <h1>Entrar</h1>
  <span>Faça seu login para ter acesso.</span>
  <form [formGroup]="loginForm">
    <div class="inputs-group">
      <div class="content">
        <label for="email">E-mail</label>
        <input
          type="email"
          formControlName="email"
          placeholder="email@mail.com"
          id="email">
      </div>
      <div class="content">
        <label for="cpf">CPF</label>
        <input
          type="text"
          formControlName="cpfCnpj"
          placeholder="000.000.000-00"
          mask="000.000.000-00"
          id="cpf">
      </div>
      <div class="buttons">
        <button class="button-enter" (click)="submitLogin()">Entrar</button>
        <button class="button-back" (click)="backToInitialPage()">Voltar</button>
      </div>
    </div>
  </form>
</div>
