import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast/toast.service';

import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Output() home: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() draw: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() profile: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() faq: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();

  @Input() logoImageFooter: any;
  @Input() facebook: string = '';
  @Input() instagram: string = '';
  @Input() kwai: string = '';
  @Input() tiktok: string = '';
  @Input() telefoneContato: string = '';

  constructor(
    private clipboard: Clipboard,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit() {}

  triggerHome(action: any) {
    this.home.emit(action);
  }
  
  triggerDraw(action: any) {
    this.draw.emit(action);
  }
  
  triggerProfile(action: any) {
    this.profile.emit(action);
  }
  
  triggerContacts(action: any) {
    this.faq.emit(action);
  }

  goToLegalTerms() {
    this.router.navigate(['/termos-legais']);
  }

  goToPrivacy() {
    this.router.navigate(['/privacidade']);
  }

  copyPhoneNumber() {
    this.clipboard.copy(this.telefoneContato);
    this.toastService.showToast('Telefone Copiado com Sucesso', 'success');
  }
}
