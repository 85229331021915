import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from 'src/app/core/services/data-service/data.service';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { TicketsService } from 'src/app/core/services/tickets/tickets.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-draw-finalized',
  templateUrl: './draw-finalized.component.html',
  styleUrls: ['./draw-finalized.component.scss']
})
export class DrawFinalizedComponent implements OnInit{

  mainBackgroundImage: string = "linear-gradient(0deg, rgba(30, 30, 30, 0.6), rgba(30, 30, 30, 0.6)), url('assets/images/creta-image-test.png') no-repeat";
  activeImage1: string = "assets/images/creta-image-test-01.png";
  activeImage2: string = "assets/images/creta-image-test-02.png";
  activeImage3: string = "assets/images/creta-image-test-03.png";

  winnerImage1: any = "../../../assets/images/winner-image-1.png";
  winnerImage2: any = "../../../assets/images/winner-image-2.png";
  winnerImage3: any = "../../../assets/images/winner-image-3.png";
  winnerImage4: any = "../../../assets/images/winner-image-4.png";

  allDraw: any[] = [];
  winnersAward: any[] = [];

  drawId: string = '';
  enterpriseId: string = '';
  
  finalizedDraw: any;
  numberMainWinner: any;

  informationData: boolean = false;

  lastWinnersList: any[] = [];

  constructor(
    private dataService: DataService,
    private enterpriseService: EnterpriseService,
    private tickets: TicketsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.drawId = this.route.snapshot.params['id'];
    this.enterpriseId = this.dataService.getEnterpriseData().id;
    this.treatLoadDraws();
    this.getLastWinners();
  }

  navigateToAllDraws() {
    this.router.navigate([`/todos-sorteios`]);
  }

  navigateToFederalLottery() {
    window.open(`https://loterias.caixa.gov.br/Paginas/Federal.aspx`);
  }

  changeImageInCard(position: any) {
    switch (position) {
      case 1: {
        this.mainBackgroundImage = `url(${this.activeImage1})`;
        break;
      }
      case 2: {
        this.mainBackgroundImage = `url(${this.activeImage2})`;
        break;
      }
      case 3: {
        this.mainBackgroundImage = `url(${this.activeImage3})`;
        break;
      }
    }
  }

  private getTicketsData() {
    this.tickets.getWinners(this.enterpriseId, this.drawId).subscribe({
      next: this.loadTicketsSuccessfull,
      error: this.drawFinalizedErrorAtAll
    });
  }

  private getLastWinners() {
    this.enterpriseService.getLastWinners(this.enterpriseId).subscribe({
      next: this.treatLoadLastWinnersSuccessfull,
      error: this.drawFinalizedErrorAtAll
    });
  }

  private treatLoadDraws() {
    if (this.dataService.getSweepstakesData() !== null || undefined) {
      this.allDraw = this.dataService.getSweepstakesData();
    }
    this.allDraw.map((draw: any) => {
      if (draw.id === this.drawId) {
        this.finalizedDraw = draw;
      }
    });
    this.treatDrawData(this.finalizedDraw);
    this.getTicketsData();
  }

  private treatDrawData(drawData: any) {
    drawData.imagens.map((image: any) => {
      switch (image.posicao) {
        case 1:
          this.activeImage1 = image.imagem;
          break;
        case 2:
          this.activeImage2 = image.imagem;
          break;
        case 3:
          this.activeImage3 = image.imagem;
          break;
      }
    });
    this.mainBackgroundImage = `url(${drawData.imagens[2].imagem}) no-repeat`;
  }

  private treatLoadLastWinnersSuccessfull = (res: any): void => {
    res.map((winner: any) => this.lastWinnersList.push(winner));
  }

  private loadTicketsSuccessfull = (res: any): void => {
    if (res.length > 0) {
      res.map((win: any) => {
        if (win.premioPrincipal === true) {
          this.numberMainWinner = win.numeroBilhete;
          //this.numberMainWinner = "00000012";
        }
      });
    }
    res.map((element: any) => {
      if (element.premioPrincipal !== true) {
        this.winnersAward.push(element);
      }
    });
    this.informationData = true;
  }

  private drawFinalizedErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
