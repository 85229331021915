<mat-card class="center-card">
  <mat-card-content>
    <div class="title">
      <h2>Termos e condições de uso</h2>
    </div>
    <div class="subtitle">
      <h2>Seja bem-vindo(a) à plataforma da Drawerfy. Os Termos e Condições de uso aqui estabelecidos abrangem não apenas o 
        uso do Site e Serviços disponibilizados pela tecnologia da Drawerfy, mas também quaisquer Políticas Adicionais que 
        estejam incorporadas como referência. Ao utilizar os serviços disponibilizados pela tecnologia da Drawerfy, você 
        concorda com os Termos aqui estabelecidos.</h2>
    </div>
    <div class="define-text">
      <h3>1. Objetivo:</h3>
      <h4>Estes Termos e Condições de Uso estabelecem as regras e determinações que irão reger a relação entre os usuários que 
        utilizam os serviços da Drawerfy por meio da sua Plataforma de tecnologia.</h4>
      <h4>Para que o Usuário possa utilizar os serviços da Drawerfy, deverá ser pessoa natural e ter plena capacidade legal, 
        nos termos da legislação aplicável. Caso o Usuário não seja legalmente capaz, o Usuário não deverá aceitar estes Termos 
        e não deverá acessar os serviços da Drawerfy.</h4>
    </div>
    <div class="define-text">
      <h3>2. Definições:</h3>
      <h4>Ao longo destes Termos, o Usuário encontrará uma série de palavras ou expressões iniciadas por letra maiúscula. Nesta seção, 
        portanto, trataremos de apresentar o significado destas.</h4>
      <div class="letters-info">
        <h4>A.	"Usuário”: engloba todos aqueles que utilizam o site e os serviços da Drawerfy, seja como Organizador, Colaborador ou Visitante.</h4>
        <h4>B.	"Serviços": referem-se às funcionalidades da tecnologia oferecida pela Drawerfy.</h4>
        <h4>C.	"Drawerfy": refere-se à Drawerfy, incluindo seus agentes, consultores e funcionários.</h4>
        <h4>D.	"Termos" ou "Termos e Condições de Uso": referem-se ao presente documento, o qual engloba todas as regras gerais e específicas, assim como todas as condições ou políticas aqui mencionadas.</h4>
        <h4>E.	“Organizadores”: são os indivíduos que possuem acesso administrativo a uma ou mais Campanhas, bem como aqueles que têm acesso administrativo a uma conta de organização na Drawerfy, assim como, refere-se também à(s) Organização(ões) que esses indivíduos podem representar.</h4>
        <h4>F.	"Campanhas": são todas as iniciativas ou projetos realizados pelos Organizadores cadastrados na plataforma da Drawerfy, com a finalidade de permitir que os Usuários visualizem e colaborem de acordo com seus interesses.</h4>
        <h4>G.	“Conteúdo": abrange todo o material incluído ou disponibilizado através da tecnologia da Drawerfy, contemplando, mas não se limitando a, software, tecnologia propriamente dita. Este conteúdo é de propriedade da Drawerfy ou de seus fornecedores, protegido por normas locais e internacionais relativas a direitos autorais.</h4>
        <h4>H.	"Contribuições": referem-se a todas as transações, monetárias ou não, realizadas para uma Campanha cadastrada por um Organizador na plataforma da Drawerfy.</h4>
        <h4>I.	"Colaborador": refere-se a qualquer utilizador que faça uma Contribuição para uma Campanha cadastrada por um Organizador na plataforma da Drawerfy.</h4>
        <h4>J.	"Violação": diz respeito a qualquer atividade que vá contra as regras e disposições destes Termos de Uso, indicando um uso não autorizado, indevido ou não permitido, abrangendo o uso de material protegido por direitos autorais ou outros direitos de propriedade intelectual.</h4>
        <h4>K.	"Itens": referem-se ao que um Colaborador pode receber em troca de fazer uma Contribuição para uma Campanha, contemplando, mas não se limitando a, entradas em um sorteio ou concurso, prêmios que um Colaborador pode receber ao vencer um sorteio ou concurso, e quaisquer outros bens tangíveis ou intangíveis fornecidos a um Colaborador.</h4>
        <h4>L.	"Plataforma": espaço de operação da tecnologia.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>3. Deveres, responsabilidade e Restrições dos Usuários:</h3>
      <h4>Ao utilizar os Serviços disponíveis pela tecnologia da Drawerfy, os Usuários estão sujeitos aos deveres e restrições relacionados nesta seção.</h4>
      <div class="letters-info">
        <h4>A.	O Usuário é responsável por sua própria conta. Para acessar os Serviços disponibilizados pela tecnologia da Drawerfy, é necessário registrar-se e criar uma conta com um endereço de e-mail e senha, devendo preencher as informações fornecidas de forma precisa e completa. O Usuário deve atualizá-las conforme necessário. Não é permitido assumir a identidade de outra pessoa, simular ser outra pessoa ou violar os direitos de qualquer indivíduo. Caso contrário, a Drawerfy poderá cancelar a conta. O Usuário é o único responsável por manter a confidencialidade de sua conta e por todas as atividades associadas a ela. A Drawerfy não se responsabiliza por qualquer perda ou dano decorrente da negligência do Usuário em cumprir esses requisitos ou do uso não autorizado de sua conta.</h4>
        <h4>B.	A aprovação dos Usuários na plataforma da Drawerfy ocorrerá no momento da criação da conta. </h4>
        <h4>C.	A Drawerfy não se responsabiliza pelos valores, aportes, contribuições ou quaisquer recursos que o Usuário aplicou, mesmo que tenham sido destinados a contas indicadas por outros usuários, limitando-se a fornecer esta plataforma e oferecer tão somente a tecnologia para a operação. É de responsabilidade do Usuário prezar e garantir a legalidade e legitimidade de suas atividades nesta plataforma.</h4>
        <h4>D.	O Usuário deve ter 18 anos ou mais para se inscrever em uma conta e usar nossos serviços. De igual forma, o Usuário titular da conta é responsável por todas as atividades realizadas por menores em seu perfil.</h4>
        <h4>E.	O Usuário é único e inteiramente responsável pela ciência e pelos compromissos fiscais. A Drawerfy não possui qualquer responsabilidade fiscal sobre as transações realizadas na plataforma.</h4>
        <h4>F.	Não é permitido aos Usuários praticar ação que viole a lei do seu país, infrinja ou viole os direitos de qualquer outra pessoa ou descumpra acordos ou obrigações legais existentes, assim como não é permitido oferecer Itens que violem as políticas, regras ou diretrizes da Drawerfy, ou que confronte qualquer lei, estatuto ou regulamento aplicável.</h4>
        <h4>G.	É de inteira responsabilidade do Usuário o fornecimento correto de todos os dados cadastrais e da conta fornecida para recebimento dos valores na plataforma, assim como a verificação, correção e atualização de tais informações. A Drawerfy não se responsabiliza por qualquer informação incorreta ou desatualizada.</h4>
        <h4>H.	Os Usuários possuem ciência e concordam que quaisquer eventuais riscos decorrentes do acesso à plataforma e serviços disponibilizados pela tecnologia da Drawerfy são de sua própria responsabilidade.</h4>
        <h4>I.	Os Usuários se comprometem a defender, indenizar e isentar a Drawerfy e seus sócios, agentes, funcionários ou procuradores de contra todas e quaisquer reivindicações, causas de ação, danos, obrigações, perdas, responsabilidades, custos ou dívidas e despesas, incluindo custos e honorários advocatícios, assim como todos os valores pagos em acordos decorrentes ou relacionados à violação destes Termos, englobando, mas não limitado a, falha no cumprimento de um Item ou reembolsar uma Contribuição ou violação de quaisquer leis.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>4. Limites das Responsabilidades da Drawerfy:</h3>
      <h4>Ao utilizar a tecnologia oferecida pela Drawerfy como Usuário, sendo caracterizado como Organizador ou como Colaborador, este se torna um contratante dos serviços de tecnologia oferecidos pela Drawerfy e, assim, inteiramente responsável pelo conteúdo e informações das Campanhas criadas. </h4>
      <h4>A Drawerfy não cria, disponibiliza, controla e/ou endossa qualquer Campanha que utilize sua tecnologia. </h4>
      <div class="letters-info">
        <h4>A.	A Drawerfy não endossa qualquer Conteúdo do Usuário, oferecendo e disponibilizando tão somente a tecnologia para a interação e operação entre os Usuários. A Drawerfy não possui qualquer envolvimento com as Campanhas.</h4>
        <h4>B.	A Drawerfy reserva-se no direito de remover Conteúdo do Usuário e/ou excluir conta de Usuário, sob seu critério e julgamento. A exclusão de conteúdo ou encerramento de contas é realizada com seriedade e cuidado, podendo exigir tempo para investigação e determinação da conduta cabível. </h4>
        <h4>C.	A Drawerfy não tem responsabilidade quanto a entrega e garantia dos itens ou reembolsos, sendo estes de exclusiva responsabilidade dos Organizadores.</h4>
        <h4>D.	A Drawerfy não oferece qualquer consultoria ou orientação tributária ou jurídica aos Usuários, sendo de total responsabilidade dos Usuários determinar como as leis tributárias ou outras leis se aplicam à execução de uma Campanha, à contribuição para uma Campanha ou à utilização dos Serviços.</h4>
        <h4>E.	A Drawerfy não possui responsabilidade e envolvimento em disputas legais entre Usuários e terceiros. Ao concordar com estes Termos, o Usuário está ciente e concorda com a exclusão da Drawerfy, seus sócios, agentes ou procuradores, de qualquer reclamação ou processo judicial ou extrajudicial iniciado contra outros Usuários ou terceiros relacionados ao uso da tecnologia.</h4>
        <h4>F.	Os Serviços oferecidos pela tecnologia da Drawerfy podem estar indisponíveis devido a manutenção programada, interrupções não planejadas ou outros problemas de funcionamento. Não há responsabilização da Drawerfy pela indisponibilidade dos Serviços ou pela perda de dados, informações ou Conteúdo do Usuário por qualquer motivo.</h4>
        <h4>G.	Os Usuários poderão solicitar a exclusão da sua conta por meio dos canais de contato disponíveis, porém isso não resultará na exclusão automática do Conteúdo do Usuário. A Drawerfy reserva-se no direito de reter certas informações conforme exigido por lei. Todas as disposições destes Termos permanecem em vigor mesmo após o encerramento de uma conta, incluindo os direitos sobre o Conteúdo.</h4>
        <h4>H.	A Drawerfy não é detentora de nenhum dever fiduciário para com os Usuários. Os Serviços, Conteúdos e quaisquer outros materiais disponibilizados ou através do uso da tecnologia disponibilizada pela Drawerfy são fornecidos sem garantia de qualquer tipo, expressa ou implícita, incluindo, mas não se limitando a, garantias implícitas de comercialização ou adequação a um propósito específico, não violação ou desempenho. </h4>
        <h4>I.	A Drawerfy não assegura que o uso dos Serviços disponíveis a partir da tecnologia será ininterrupto, livre de erros, que defeitos serão corrigidos ou que os serviços ou servidor(es) em que os serviços estão hospedados ou quaisquer serviços disponíveis em qualquer plataforma de terceiros estejam livres de vírus ou outros componentes prejudiciais. O uso dos Serviços  disponíveis a partir da tecnologia é totalmente por conta e risco dos Usuário.</h4>
        <h4>J.	A Drawerfy não possui envolvimento e responsabilidade acerca da qualidade, segurança, moralidade e/ou legalidade de qualquer Campanha, Item ou Contribuição, tampouco assegura a veracidade do Conteúdo do Usuário postado na Plataforma.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>5. Da Propriedade Intelectual</h3>
      <div class="letters-info">
        <h4>A.	Os serviços, conteúdos e marcas da Drawerfy são de sua propriedade e, por isso, são protegidos por diversos meios legais, incluindo direitos autorais, marcas registradas, patentes e segredos comerciais, além de outras leis de propriedade intelectual internacionais. Ao utilizar os Serviços disponíveis por meio da tecnologia da Drawerfy, os Usuários concordam em respeitar todos os direitos autorais e outras restrições legais, bem como as informações contidas em qualquer conteúdo, serviços ou marcas da Drawerfy.</h4>
        <h4>B.	O Conteúdo do Usuário continua sendo de sua propriedade e é protegido pelas leis de direitos autorais e outras leis de propriedade intelectual aplicáveis. Ao enviar Conteúdos do Usuário para a tecnologia disponibilizada pela Drawerfy, o Usuário concede a Drawerfy o direito de utilizar esse conteúdo.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>6. Responsabilidades e deveres do Organizador</h3>
      <div class="letters-info">
        <h4>A.	O Organizador que recebe contribuições de Campanhas criadas e operadas através da tecnologia da Drawerfy são exclusivamente responsáveis por cumprir todas as exigências legais, regulamentares e tributárias aplicáveis à sua Campanha, organização e jurisdição.</h4>
        <h4>B.	O Organizador declara e garante que confiou em seus próprios advogados e contadores em relação a toda e qualquer orientação com relação à(s) Campanha(s) por ele criada.</h4>
        <h4>C.	O Organizador concorda em indenizar e isentar a Drawerfy de toda e qualquer reclamação de qualquer Colaborador, outra pessoa ou Entidade em decorrência de resultados de qualquer Campanha que seja proibida no todo ou em parte por qualquer autoridade regulatória.</h4>
        <h4>D.	O Organizador está ciente e reconhece que quaisquer registros, licenças, garantias ou outros requisitos que possam se aplicar à sua Campanha são de sua exclusiva responsabilidade, devendo identificar e preencher antes de solicitar as Contribuições.</h4>
        <h4>E.	O Organizador é exclusivamente responsável por fornecer declaração de elegibilidade e liberação de responsabilidade/publicidade ao(s) vencedor(es) em potencial de sua Campanha, conforme exigido por qualquer autoridade reguladora.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>7. Sobre a criação das Campanhas:</h3>
      <div class="letters-info">
        <h4>A.	A responsabilidade pelas informações fornecidas na criação da Campanha, como imagens e vídeos publicados, é inteiramente do Organizador que a criou.</h4>
        <h4>B.	O Organizador está ciente de que só poderá criar Campanhas com produtos ou serviços que tenham origem e natureza lícitas, bem como que não violem qualquer direito de terceiros, incluindo direitos de propriedade intelectual, não violem os direitos de qualquer pessoa ou entidade, incluindo, mas não se limitando aos direitos de publicidade ou privacidade; não violem direitos de menores de idade, não desrespeitem os direitos dos consumidores, nos termos da lei aplicável, não apresentam caráter difamatório, e que sua comercialização é permitida e está em conformidade com a legislação brasileira vigente.</h4>
        <h4>C.	Os Organizadores designam a Drawerfy como agente limitado do Organizador, exclusivamente para cadastrar suas Campanhas por meio de sua tecnologia. A Drawerfy não processa ou facilita a liquidação de pagamentos ao Organizador, nem realiza transações financeiras. A recepção, retenção e liquidação de pagamentos são realizadas por processadores de pagamento por meio de API de pagamento.</h4>
        <h4>D.	A Drawerfy de reserva ao direito de, havendo suspeita de fraude ou problemas de compliance, suspender a Campanha e bloquear o Organizador.</h4>
        <h4>E.	Os Organizadores, ao criarem uma Campanha, poderão visualizar as Campanhas em andamento, acessar o volume de recursos arrecadados, saber quem são os colaboradores assíduos, o histórico das arrecadações, menu de configurações e link de acesso direto ao Suporte da Plataforma.</h4>
        <h4>F.	Após a criação da Campanha, alguns dados como, mas não se limitando a,  o título, valor por cota, adicionar promoções, medidas de incentivo e/ou cupons e ainda a data de encerramento da Campanha (podendo ser prorrogada ou antecipada) poderão ser alterados pelo Organizador, desde que a alteração seja tentada em data anterior ao marco de encerramento da ação.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>8. Taxas e recebimento da Contribuição:</h3>
      <div class="letters-info">
        <h4>A.	A utilização da tecnologia da Drawerfy implica na cobrança de taxa no valor de 3% sob o valor total da chamada API 'pedido aprovado', a título de uso da tecnologia, a qual será cobrada diariamente via cartão de crédito. </h4>
        <h4>B.	O único pagamento recebido pela Drawerfy do Organizador é a taxa a ser paga a título de utilização da tecnologia, sem receber qualquer benefício com as Contribuições das Campanhas realizadas pelos Organizadores através da tecnologia disponibilizada pela Drawerfy.</h4>
        <h4>C.	O Organizador da Campanha utilizará os Meios de Pagamento aceito pela  Drawerfy para arrecadar fundos em suas Campanhas, recebendo esses recursos por meio de depósito direto na conta bancária indicada, estando ciente de que o cadastro no gateway de pagamento é de sua inteira responsabilidade.</h4>
        <h4>D.	A responsabilidade de eventual reembolso para o Contribuinte é de exclusividade do Organizador e do processador de pagamento por ele escolhido. </h4>
      </div>
    </div>
    <div class="define-text">
      <h3>9. Contribuição das Campanhas:</h3>
      <div class="letters-info">
        <h4>A.	O Colaborador, ao fazer uma Contribuição para uma Campanha, declara que realizada de forma voluntária e sob sua total responsabilidade.</h4>
        <h4>B.	Os Colaboradores são exclusivamente responsáveis por determinar como tratar suas Contribuições e quaisquer Itens recebidos para fins fiscais.</h4>
        <h4>C.	A Drawerfy não assegura que as contribuições serão utilizadas em consoante com o prometido, que os Organizadores irão fornecer os itens prometidos ou que a Campanha alcançará seus objetivos. Da mesma forma, a Drawerfy não endossa, garante, representa ou oferece garantias sobre a qualidade, segurança, moralidade e legalidade de qualquer Campanha, Item ou Contribuição, nem sobre a veracidade ou precisão do Conteúdo do Usuário publicado nos Serviços.</h4>
        <h4>D.	A Drawerfy não possui qualquer responsabilidade com eventual reembolso para o Contribuinte, devendo este ser pleiteado diretamente com o Organizador.</h4>
      </div>
    </div>
    <div class="define-text">
      <h3>10. Vigência, atualização e revisão:</h3>
      <h4>Os Termos e Condições de Uso estarão em vigor por tempo indeterminado. Esteja ciente de que reservamos o direito de modificar esses Termos a qualquer momento, e tais alterações estarão vigentes assim que uma versão revisada for publicada. </h4>
      <h4>Assim, ao continuar a utilizar nossos Serviços após qualquer modificação, você estará aceitando os Termos atualizados. A data da última revisão estará sempre disponível para sua referência.</h4>
    </div>
  </mat-card-content>
</mat-card>
