import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';

@Component({
  selector: 'app-middle-draw-card',
  templateUrl: './middle-draw-card.component.html',
  styleUrls: ['./middle-draw-card.component.scss']
})
export class MiddleDrawCardComponent implements OnInit {

  @Output() clickToDraw: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();

  @Input() data: any;
  @Input() index: any;
  @Input() linearGradient: any;
  @Input() image: any;
  @Input() progressValue: any;

  @Input() title: string = '';
  @Input() subtitle: string = '';

  resultPercent: number = 0;
  
  constructor() { }

  ngOnInit() {
    this.treatFirstImage(this.data?.imagens);
    this.calculateProgress(this.data);
  }

  triggerToDraw(action: any) {
    this.clickToDraw.emit(new TableActionEmitted(action,this.data, this.index));
  }

  calculateProgress(sorteio: any): void {
    if (sorteio.quantidadeBilhetesDisponivel === 0){
      this.resultPercent = 100;
    } else {
      sorteio ? this.resultPercent = parseFloat(((sorteio.quantidadeBilhetesVendidos / (sorteio.quantidadeBilhetesVendidos + sorteio.quantidadeBilhetesDisponivel)) * 100).toFixed(2))
                : this.resultPercent = 0
    }
  }

  treatFirstImage(images: any[]) {
    images.map((image: any) => {
      if(image.posicao === 1) {
        this.linearGradient = `url(${image.imagem}) no-repeat`;
      }
    });
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  generateLinearGradient(): string {
    const angle = Math.floor(Math.random() * 360);
    let color1 = this.getRandomColor();
    let color2 = this.getRandomColor();

    while (color1 === '#FFFFFF' || color2 === '#FFFFFF') {
      color1 = this.getRandomColor();
      color2 = this.getRandomColor();
    }
    return `linear-gradient(${angle}deg, ${color1}, ${color2})`;
  }
}
