import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data-service/data.service';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { ISorteio } from 'src/app/core/services/types/sorteio-interface';

@Component({
  selector: 'app-all-draws',
  templateUrl: './all-draws.component.html',
  styleUrls: ['./all-draws.component.scss'],
})
export class AllDrawsComponent implements OnInit {

  countdown: string = '';
  selectedTab: string = 'ativos';
  allDrawsBackgroundImage: string = "url('assets/images/sorteio_ram.png') no-repeat";

  enterpriseData: any;
  price: any;
  mainDraw: any;
  dataLoaded: boolean = false;

  draws: any[] = [];
  activeDraws: any[] = [];
  finishedDraws: any[] = [];
  upcomingDraws: any[] = [];

  constructor(
    private enterpriseService: EnterpriseService,
    private dataService: DataService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.enterpriseData = this.dataService.getEnterpriseData();
    this.getAllDraws();
  }

  updateSelectedTab(tab: string) {
    this.selectedTab = tab;
  }

  navigateToActiveDraw(data: any): void {
    this.router.navigate([`/sorteio-ativo/${data.id}`]);
  }

  navigateToFinalizedDraw(data: any): void {
    this.router.navigate([`/sorteio-finalizado/${data.id}`]);
  }

  private getAllDraws() {
    const enterpriseId = this.enterpriseData.id;
    return this.enterpriseService.getSweepstakes(enterpriseId).subscribe({
      next: (res: any) => {
        res.sorteios.map((sorteio: any) => {
          if (sorteio.statusSorteio === 'Ativo') {
            this.activeDraws.push(sorteio);
          } else if(sorteio.statusSorteio === 'Finalizado') {
            this.finishedDraws.push(sorteio);
          } else {
            this.upcomingDraws.push(sorteio);
          }
        });
        this.draws = res.sorteios;
        this.draws.map((draw: any) => {
          if (draw.principal === true && draw.statusSorteio === 'Ativo') {
            this.mainDraw = draw
          }
        });
        this.allDrawsBackgroundImage = `url('${res.sorteios[0].imagens[0].imagem}') no-repeat`;
        this.dataLoaded = true;
      },
      error: (err: any) => {
        this.toastService.showToast(this.parseError(err), 'danger');
      },
    });
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ? 
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
