<app-fullscreen-loading [visible]="initialLoading">
</app-fullscreen-loading>

<mat-card class="center-card" *ngIf="!initialLoading">
  <div *ngIf="isLogged" class="row row-group">
    <app-main-home-card class="app-main-home-card-container"
      [backgroundImage]="mainBackgroundImage"
      (clickToDraw)="navigateToDraw($event)"
      (whatsAppGroup)="navigateToWhatsapp()"
      (telegramGroup)="navigateToTelegram()"
      [cardRightTitle]="mainTitle"
      [cardRightSubtitle]="mainDescription"
      [data]="mainData">
    </app-main-home-card>
    <div class="size-div-16-inline"></div>

    <div class="whatsapp-group">
      <h1>{{ mainTitle }}</h1>
      <button mat-button class="participate-button" *ngIf="mainWhatsapp !== ''" (click)="navigateToWhatsapp()">
        <div class="row">
          <img class="img-size-logo" src="../../../../assets/images/logo-whatsapp.png">
          <h3 class="font-14">Participe do Grupo no WhatsApp</h3>
        </div>
      </button>
      <button mat-button class="participate-button-telegram" *ngIf="mainTelegram !== ''" (click)="navigateToTelegram()">
        <div class="row">
          <img class="img-size-logo" src="../../../../assets/images/telegram-icon.png">
          <h3 class="font-14">Participe do Grupo no Telegram</h3>
        </div>
      </button>
      <div class="dow-right-card" *ngIf="mainData?.exibirTemporizador">
        <div class="row" *ngIf="mainData?.statusSorteio === 'Ativo'">
          <mat-icon>timer</mat-icon>
          <h3>{{ restingTime }}</h3>
        </div>
        <div class="row" *ngIf="mainData?.statusSorteio === 'Finalizado'">
          <mat-icon>timer</mat-icon>
          <h3>Encerrado!</h3>
        </div>
      </div>
    </div>

    <mat-card class="inside-left-card">
      <mat-card-content>
        <div class="row">
          <button mat-button class="take-button" (click)="navigateToActiveDraw(mainData.id)">
            <h3>Quero Garantir o Meu!</h3>
          </button>
          <div class="div-card" (click)="navigateToLegalTerms()">
            <span class="weight-400" (click)="navigateToLegalTerms()">Ver Regulamento</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="column cards">
      <mat-card class="user-card">
        <mat-card-content>
          <h3>{{ userName }}</h3>
          <h6>{{ userEmail }}</h6>
        </mat-card-content>
      </mat-card>
      <div class="row-order">
        <img src="../../../assets/images/mini-pig.svg" alt="Cofrinho" />
        <h4>Últimas Compras</h4>
      </div>
      <div class="orders-column" *ngIf="isLogged">
        <app-latest-orders *ngIf="this.clientOrders.length >= 1"
          [backgroundImage]="lastOrderImage1"
          [drawName]="this.clientOrders[0]?.dadosSorteio?.titulo"
          [drawCode]="'R$' + this.clientOrders[0]?.valorCompra.toLocaleString('pt-br', {minimumFractionDigits: 2})"
          [statusPayment]="this.clientOrders[0]?.statusCompra">
        </app-latest-orders>
        <app-latest-orders *ngIf="this.clientOrders.length >= 2"
          [backgroundImage]="lastOrderImage2"
          [drawName]="this.clientOrders[1]?.dadosSorteio?.titulo"
          [drawCode]="'R$' + this.clientOrders[1]?.valorCompra.toLocaleString('pt-br', {minimumFractionDigits: 2})"
          [statusPayment]="this.clientOrders[1]?.statusCompra">
        </app-latest-orders>
        <app-latest-orders *ngIf="this.clientOrders.length >= 3"
          [backgroundImage]="lastOrderImage3"
          [drawName]="this.clientOrders[2]?.dadosSorteio?.titulo"
          [drawCode]="'R$' + this.clientOrders[2]?.valorCompra.toLocaleString('pt-br', {minimumFractionDigits: 2})"
          [statusPayment]="this.clientOrders[2]?.statusCompra">
        </app-latest-orders>
      </div>
    </div>
  </div>

  <div *ngIf="!isLogged" class="row row-group">
    <app-main-home-card class="app-main-home-card-container-not-logged"
      [backgroundImage]="mainBackgroundImage"
      (clickToDraw)="navigateToDraw($event)"
      (whatsAppGroup)="navigateToWhatsapp()"
      [cardRightTitle]="mainTitle"
      [cardRightSubtitle]="mainDescription"
      [data]="mainData">
    </app-main-home-card>
    <div class="size-div-16-inline"></div>

    <div class="whatsapp-group">
      <h1>{{ mainTitle }}</h1>
      <button mat-button class="participate-button" *ngIf="mainWhatsapp !== ''" (click)="navigateToWhatsapp()">
        <div class="row">
          <img class="img-size-logo" src="../../../../assets/images/logo-whatsapp.png">
          <h3 class="font-14">Participe do Grupo no WhatsApp</h3>
        </div>
      </button>
      <button mat-button class="participate-button-telegram" *ngIf="mainTelegram !== ''" (click)="navigateToTelegram()">
        <div class="row">
          <img class="img-size-logo" src="../../../../assets/images/telegram-icon.png">
          <h3 class="font-14">Participe do Grupo no Telegram</h3>
        </div>
      </button>
      <div class="dow-right-card" *ngIf="mainData?.exibirTemporizador">
        <div class="row" *ngIf="mainData?.statusSorteio === 'Ativo'">
          <mat-icon>timer</mat-icon>
          <h3>{{ restingTime }}</h3>
        </div>
        <div class="row" *ngIf="mainData?.statusSorteio === 'Finalizado'">
          <mat-icon>timer</mat-icon>
          <h3>Encerrado!</h3>
        </div>
      </div>
    </div>

    <mat-card class="inside-left-card">
      <mat-card-content>
        <div class="row">
          <button mat-button class="take-button" (click)="navigateToActiveDraw(mainData.id)">
            <h3>Quero Garantir o Meu!</h3>
          </button>
          <div class="div-card" (click)="navigateToLegalTerms()">
            <span class="weight-400" (click)="navigateToLegalTerms()">Ver Regulamento</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>    
  </div>
  
  <div class="size-div-24-bottom"></div>
  <div class="draw-card-group">
    <div class="draw-card-container">
      <ng-container *ngFor="let sorteio of sorteios; let i = index">
        <ng-container *ngIf="i >= 0">
          <app-middle-draw-card
            [data]="sorteio"
            (clickToDraw)="navigateToDraw($event)">
          </app-middle-draw-card>
        </ng-container>
      </ng-container>
    </div>
    <mat-card class="detail-card" style="background: var(--cor-secundaria); border: 2px solid var(--cor-primaria)">
      <div class="row-detail">
        <div class="column-detail">
          <h4 class="weight-700">Quero Aumentar Minhas Chances</h4>
          <div class="size-div-12-bottom"></div>
          <h4 class="font-size-12">Veja todos os sorteios</h4>
          <div class="size-div-12-bottom"></div>
          <button class="now-button" (click)="navigateToAllDraw()">
            Aumentar Agora <img src="../../../assets/images/arrow.png" alt="Arrow Image">
          </button>
        </div>
        <img class="hand-right" src="../../../assets/images/Dark-white-in-Jumper3.png">
      </div>
    </mat-card>
  </div>
  <div class="row"></div>
  <div class="side-div-24-bottom"></div>
  <div class="row-order">
    <img src="../../../assets/images/tabler_medal.png" alt="Medalha" />
    <h4>Últimos Ganhadores</h4>
  </div>
  <div class="row winner-group" *ngIf="lastWinnersList.length > 0">
    <ng-container *ngFor="let winner of lastWinnersList; let i = index">
      <app-winner-image-card 
        [backgroundImage]="winner.foto"
        [title]="winner.tituloResgate">
      </app-winner-image-card>
    </ng-container>
  </div>
  <div *ngIf="lastWinnersList.length === 0">
    <mat-card class="no-winners-card">
      <mat-card-content>
        <h2>Não Apresenta Novos Ganhadores Até o Momento</h2>
      </mat-card-content>
    </mat-card>
  </div>
  <div style="margin: 20px auto; font-size: 16px; font-weight: 400; text-decoration: none; color: #222;" href="#"></div>
  <div>
    <app-how-work-card></app-how-work-card>
  </div>
  <!-- <button (click)="opa()">BOTAO DE ALTERNANCIA</button>
  <p>inicial-page works!</p> -->
</mat-card>
