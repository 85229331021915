import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from 'src/app/core/services/data-service/data.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-active-draw',
  templateUrl: './active-draw.component.html',
  styleUrls: ['./active-draw.component.scss']
})
export class ActiveDrawComponent implements OnInit{

  mainBackgroundImage: any = "url('assets/images/creta-image-test.png') no-repeat";
  isActive: boolean = true;

  activeImage1: any;
  activeImage2: any;
  activeImage3: any;

  allDraw: any;
  drawId: string = '';
  activeDraw: any;

  buyTicketData: any = {};
  dataToQuoteAward: any = {};
  dateDraw: any;

  packages: any[] = [];

  tickets: any = [];
  packagesToSend: any[] = [];
  packagesToShow: any[] = [];

  freeTickets: number = 0;
  subtotalPackages: number = 0;
  subtotalQuantityPackages: number = 0;
  priceTickets: number = 0;
  countTickets: number = 0;

  restingTickets: boolean = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.drawId = this.route.snapshot.params['id'];
    this.treatLoadDraws()
  }

  navigateToAllDraw() {
    this.router.navigate([`/todos-sorteios`]);
  }

  navigateToWhatsapp(event: any) {
    window.open(`${event.value.numeroWhatsapp}`);
  }

  navigateToTelegram(event: any) {
    window.open(`${event.value.numeroTelegram}`);
  }

  navigateToLegalTerms() {
    this.router.navigate([`/termos-legais`]);
  }

  changeImageInCard(position: any) {
    switch (position) {
      case 1: {
        this.mainBackgroundImage = `url(${this.activeImage1})`;
        break;
      }
      case 2: {
        this.mainBackgroundImage = `url(${this.activeImage2})`;
        break;
      }
      case 3: {
        this.mainBackgroundImage = `url(${this.activeImage3})`;
        break;
      }
    }
  }

  triggerMoreDrawsButton() {
    this.router.navigate([`/todos-sorteios`]);
  }

  insertQuantityCardTicket(unitValue: number, priceValue: number, packageId: string) {
    if (unitValue + this.countTickets > this.buyTicketData.quantidadeMaximaBilhetes) {
      this.toastService.showToast('Quantidade de Bilhetes Excedida!', 'warning');
    } else {
      this.countTickets += unitValue;
      this.priceTickets += priceValue;
      this.packagesToSend.push({
        idPacote: packageId,
        valor: priceValue
      });
      
      let existingPackageToShow = this.packagesToShow.find(pkg => pkg.idPacote === packageId);
      if (existingPackageToShow) {
        existingPackageToShow.quantidade += 1;
      } else {
        this.packagesToShow.push({
          idPacote: packageId,
          valor: priceValue,
          bilhetes: unitValue,
          quantidade: 1
        });
      }
      this.subtotalPackages += priceValue;
      this.subtotalQuantityPackages += unitValue;
    }
  }

  removeQuantityCardTicket(unitValue: number, priceValue: number, packageId: string) {
    if (this.countTickets - unitValue < this.buyTicketData.quantidadeMinimaBilhetes) {
      this.toastService.showToast(`Quantidade Mínima de Bilhetes: ${this.buyTicketData.quantidadeMinimaBilhetes}!`, 'warning');
    } else {
      let indexToRemove = this.packagesToSend.findIndex(pkg => pkg.idPacote === packageId);
      if (indexToRemove !== -1) {
        this.packagesToSend.splice(indexToRemove, 1);
      }

      let existingPackageToShow = this.packagesToShow.find(pkg => pkg.idPacote === packageId);
      if (existingPackageToShow && existingPackageToShow. quantidade > 0) {
        existingPackageToShow.quantidade -= 1;
        this.countTickets -= unitValue;
        this.priceTickets -= priceValue  
        if (existingPackageToShow.quantidade === 0) {
          this.packagesToShow = this.packagesToShow.filter(pkg => pkg.idPacote !== packageId);
        }
      }
      this.subtotalPackages -= priceValue;
      this.subtotalQuantityPackages -= unitValue;
    }
  }

  plusOneTicket() {
    if (this.countTickets + 1 > this.buyTicketData.quantidadeMaximaBilhetes) {
      this.toastService.showToast('Quantidade de Bilhetes Excedida!', 'warning');
    } else {
      this.countTickets++;
      this.freeTickets++;
      this.priceTickets += this.buyTicketData.valorBilhete;
    }
  }

  mineOneTicket() {
    if (this.freeTickets === 0) {
      this.toastService.showToast('Valor Mínimo Atingido!', 'warning');
    } else {
      if (this.countTickets - 1 < this.buyTicketData.quantidadeMinimaBilhetes) {
        this.toastService.showToast(`A Quantidade Mínima de Bilhetes: ${this.buyTicketData.quantidadeMinimaBilhetes}!`, 'warning');
      } else {
        this.countTickets--;
        this.freeTickets--;
        this.priceTickets -= this.buyTicketData.valorBilhete;
      }
    }
  }

  navigateToTicketDraw() {
    const data = {
      quantidadeCotas: this.countTickets,
      valorTotalCotas: this.priceTickets,
      idEmpresa: this.buyTicketData.idEmpresa,
      idSorteio: this.buyTicketData.idSorteio,
      valorBilhete: this.buyTicketData.valorBilhete,
      avulsoBilhete: this.freeTickets,
      pacotes: JSON.stringify(this.packagesToSend),
      pacotesDetalhados: JSON.stringify(this.packagesToShow)
    }
    this.router.navigate([`/checkout-draw`], {queryParams: data});
  }

  updateFreeTickets(event: any) {
    if (event.target.value < this.buyTicketData.quantidadeMinimaBilhetes) {
      this.toastService.showToast(`A Quantidade Mínima de Bilhetes: ${this.buyTicketData.quantidadeMinimaBilhetes}`);
      this.freeTickets = this.buyTicketData.quantidadeMinimaBilhetes;
    } else if (event.target.value > this.buyTicketData.quantidadeMaximaBilhetes) {
      this.toastService.showToast(`A Quantidade Máxima de Bilhetes: ${this.buyTicketData.quantidadeMaximaBilhetes}`);
      this.freeTickets = this.buyTicketData.quantidadeMinimaBilhetes;      
    } else {
      if (this.packagesToSend.length >= 1) {
        this.freeTickets = Number(event.target.value);
        this.countTickets = this.freeTickets + this.subtotalQuantityPackages;
        this.priceTickets = (event.target.value * this.buyTicketData.valorBilhete) + this.subtotalPackages;
      } else {
        this.countTickets = Number(event.target.value);
        this.freeTickets = Number(event.target.value);
        this.priceTickets = (event.target.value * this.buyTicketData.valorBilhete);
      }
    }
  }

  private treatLoadDraws() {
    if (this.dataService.getSweepstakesData() !== null || undefined) {
      this.allDraw = this.dataService.getSweepstakesData();
    }
    this.allDraw.map((draw: any) => {
      if (draw.id === this.drawId) {
        this.activeDraw = draw;
      }
    });
    this.dataToQuoteAward = {
      idEmpresa: this.activeDraw.idEmpresa,
      idSorteio: this.drawId
    }
    this.activeDraw?.pacotes?.map((pacote: any) => {
      if (pacote.tipoPacote === 1) {
        this.packages.push(pacote);
      }
    });
    this.treatDrawData(this.activeDraw);
  }

  private treatDrawData (drawData: any) {
    drawData.imagens.map((image: any) => {
      switch (image.posicao) {
        case 1:
          this.activeImage1 = image.imagem;
          this.mainBackgroundImage = `url(${image.imagem}) no-repeat`;
          break;
        case 2:
          this.activeImage2 = image.imagem;
          break;
        case 3:
          this.activeImage3 = image.imagem;
          break;
      }
    });
    this.dateDraw = drawData.dataSorteio;
    this.buyTicketData = {
      'idEmpresa': drawData.idEmpresa,
      'idSorteio': this.drawId,
      'pacotes': this.packages,
      'valorBilhete': drawData.valorBilhete,
      'quantidadeMaximaBilhetes': drawData.quantidadeMaximaBilhetes,
      'quantidadeMinimaBilhetes': drawData.quantidadeMinimaBilhetes,
      'quantidadeBilhetesDisponivel': drawData.quantidadeBilhetesDisponivel,
      'quantidadeBilhetesVendidos': drawData.quantidadeBilhetesVendidos,
    };
    this.tickets = this.buyTicketData.pacotes;
    this.freeTickets += this.buyTicketData?.quantidadeMinimaBilhetes;
    this.priceTickets += this.buyTicketData?.valorBilhete * this.buyTicketData?.quantidadeMinimaBilhetes;
    this.restingTickets = this.buyTicketData?.quantidadeBilhetesDisponivel === 0 ? true : false;
    this.countTickets = this.freeTickets + this.subtotalPackages;
  }

  private treatActiveDrawErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }

}
