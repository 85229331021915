import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

import { ToastService } from 'src/app/core/services/toast/toast.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private router: Router,
  ) { }

  paymentData: any;
  paymentPending: boolean = true;
  qrData: string = '';

  ngOnInit() {
    this.paymentData = this.data?.payment?.data;
    this.qrData = this.data?.payment?.data?.transacao?.urlPix;
    this.registerUser({
      token: this.data?.payment?.data?.token
    });
  }

  backToInitialPage(data?: any) {
    this.router.navigate([`/`]);
    this.dialogRef.close();
  }

  copyText() {
    this.clipboard.copy(this.data?.payment?.data?.transacao?.urlPix);
    this.toastService.showToast('Chave pix Copiada com Sucesso', 'success');
  }

  navigateToWhatsapp() {
    window.open(`${this.data?.numeroWhatsapp}`);
  }

  navigateToTelegram() {
    window.open(`${this.data?.numeroTelegram}`);
  }

  private registerUser(token: any) {
    if (token) {
      this.authService.register(token);
    }
  }
}
