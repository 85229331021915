import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';

interface ITickets {
  id: number;
  price: number;
  quantity: number;
  chance: string;
}

@Component({
  selector: 'app-buy-ticket',
  templateUrl: './buy-ticket.component.html',
  styleUrls: ['./buy-ticket.component.scss']
})
export class BuyTicketComponent implements OnInit {
  
  @Output() ticketToDraw: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();

  @Input() data: any;
  @Input() index: any;

  tickets: any = [];
  packagesToSend: any[] = [];
  packagesToShow: any[] = [];

  freeTickets: number = 0;
  subtotalPackages: number = 0;
  priceTickets: number = 0;
  countTickets: number = 0;

  restingTickets: boolean = false;

  constructor(
    private enterpriseService: EnterpriseService,
    private toastService: ToastService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tickets = this.data.pacotes;
    this.freeTickets += this.data?.quantidadeMinimaBilhetes;
    this.priceTickets += this.data?.valorBilhete * this.data?.quantidadeMinimaBilhetes;
    this.restingTickets = this.data?.quantidadeBilhetesDisponivel === 0 ? true : false;
    this.countTickets = this.freeTickets + this.subtotalPackages;
  }

  insertQuantityCardTicket(unitValue: number, priceValue: number, packageId: string) {
    if (unitValue + this.countTickets > this.data?.quantidadeMaximaBilhetes) {
      this.toastService.showToast('Quantidade de Bilhetes Excedida!', 'warning');
    } else {
      this.countTickets += unitValue;
      this.priceTickets += priceValue;
      this.packagesToSend.push({
        idPacote: packageId,
        valor: priceValue
      });
      
      let existingPackageToShow = this.packagesToShow.find(pkg => pkg.idPacote === packageId);
      if (existingPackageToShow) {
        existingPackageToShow.quantidade += 1;
      } else {
        this.packagesToShow.push({
          idPacote: packageId,
          valor: priceValue,
          bilhetes: unitValue,
          quantidade: 1
        });
      }
      this.subtotalPackages += priceValue
    }
  }

  removeQuantityCardTicket(unitValue: number, priceValue: number, packageId: string) {
    if (this.countTickets - unitValue < this.data?.quantidadeMinimaBilhetes) {
      this.toastService.showToast(`Quantidade Mínima de Bilhetes: ${this.data?.quantidadeMinimaBilhetes}!`, 'warning');
    } else {
      let indexToRemove = this.packagesToSend.findIndex(pkg => pkg.idPacote === packageId);
      if (indexToRemove !== -1) {
        this.packagesToSend.splice(indexToRemove, 1);
      }

      let existingPackageToShow = this.packagesToShow.find(pkg => pkg.idPacote === packageId);
      if (existingPackageToShow && existingPackageToShow. quantidade > 0) {
        existingPackageToShow.quantidade -= 1;
        this.countTickets -= unitValue;
        this.priceTickets -= priceValue  
        if (existingPackageToShow.quantidade === 0) {
          this.packagesToShow = this.packagesToShow.filter(pkg => pkg.idPacote !== packageId);
        }
      }
    }
  }

  plusOneTicket() {
    if (this.countTickets + 1 > this.data?.quantidadeMaximaBilhetes) {
      this.toastService.showToast('Quantidade de Bilhetes Excedida!', 'warning');
    } else {
      this.countTickets++;
      this.freeTickets++;
      this.priceTickets += this.data?.valorBilhete;
    }
  }

  mineOneTicket() {
    if (this.freeTickets === 0) {
      this.toastService.showToast('Valor Mínimo Atingido!', 'warning');
    } else {
      if (this.countTickets - 1 < this.data?.quantidadeMinimaBilhetes) {
        this.toastService.showToast(`A Quantidade Mínima de Bilhetes: ${this.data?.quantidadeMinimaBilhetes}!`, 'warning');
      } else {
        this.countTickets--;
        this.freeTickets--;
        this.priceTickets -= this.data?.valorBilhete;
      }
    }
  }

  navigateToTicketDraw() {
    const data = {
      quantidadeCotas: this.countTickets,
      valorTotalCotas: this.priceTickets,
      idEmpresa: this.data?.idEmpresa,
      idSorteio: this.data?.idSorteio,
      valorBilhete: this.data?.valorBilhete,
      avulsoBilhete: this.freeTickets,
      pacotes: JSON.stringify(this.packagesToSend),
      pacotesDetalhados: JSON.stringify(this.packagesToShow)
    }
    this.router.navigate([`/checkout-draw`], {queryParams: data});
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
