import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from 'src/app/core/services/data-service/data.service';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  data: any = [];
  logoImage: any;
  facebook: string = '';
  instagram: string = '';
  kwai: string = '';
  tiktok: string = '';
  telefoneContato: string = '';
  initialLoading: boolean = true;
  favIconUrl: string= '';

  constructor(
    private enterpriseService: EnterpriseService,
    private dataService: DataService,
    private router: Router,
    private theme: ThemeService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    //this.loadEnterpriseData('sorteiodois.sourcefile.com.br');
    const mainUrl = `${window.location.hostname}`;
    if (mainUrl === 'localhost') {
      this.loadEnterpriseData('sorteioum.sourcefile.com.br');
    } else {
      this.loadEnterpriseData(mainUrl);
    }
    setInterval(() =>  {
      localStorage.setItem('favIconUrl', this.favIconUrl);
    }, 1000);
  }

  navigateToHome() {
    this.router.navigate([`/`]);
  }

  navigateToActiveDraw() {
    this.router.navigate([`/todos-sorteios`]);
  }

  navigateToProfile() {
    this.router.navigate([`/user-settings`]);
  }

  navigateToWinners() {
    this.router.navigate([`/sorteio-finalizado`]);
  }

  navigateToFAQ() {
    this.router.navigate([`/faq`]);
  }

  changeColor(colorPrimary: any, colorSecondary: any, buttonColor: any) {
    this.theme.changeTheme({
      '--cor-primaria': {
        variable: '--cor-primaria',
        value: colorPrimary,
      }
    });
    this.theme.changeTheme({
      '--cor-secundaria': {
        variable: '--cor-secundaria',
        value: colorSecondary,
      }
    });
    this.theme.changeTheme({
      '--cor-botao': {
        variable: '--cor-botao',
        value: buttonColor,
      }
    });
  }

  private loadEnterpriseData(domain: string) {
    let payload = { dominio: domain }
    this.enterpriseService.postLoadEnterpriseData(payload).subscribe({
      next: this.treatLoadConfigsDomainSuccessfull,
      error: this.treatInicialPageErrorAtAll
    });
  }

  private treatLoadConfigsDomainSuccessfull = (res: any): void => {
    this.dataService.setEnterpriseData(res);
    this.favIconUrl = res.favIcon;
    document.title = res.nome;
    this.logoImage = res.logotipo;
    this.facebook = res.facebook;
    this.instagram = res.instagram;
    this.kwai = res.kwai;
    this.tiktok = res.tikTok;
    this.telefoneContato = res.telefoneContato;
    this.changeColor(res.corPrimaria, res.corSecundaria, res.corBotao);
    this.initialLoading = false;
  }

  private treatInicialPageErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'danger');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
