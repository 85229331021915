<div class="container">
  <mat-card class="main-card" [ngStyle]="{'background': backgroundImage}">
    <mat-card-content>
      <mat-card class="inside-right-card">
        <span [ngClass]="isActive == true ? 'is-active' : 'not-active'">
          <ng-container *ngIf="isActive; else finalizedText">
            <img src="../../../../assets/images/clock.png" alt=""> Acaba em: {{ restingTime }}
          </ng-container>
          <ng-template #finalizedText>
            <img src="../../../../assets/images/clock-close.png" alt="">Finalizado
          </ng-template>
        </span>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>
