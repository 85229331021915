<div class="container">
  <div class="navbar">
    <a class="money-bag" href="#">
      Ganhadores
    </a>
  </div>
  <div class="overflow">
    <div class="table">
      <div class="thead">
        <div class="content item head">
          <span style="width: 48px;"></span>
          <span>Nome do Ganhador</span>
        </div>
        <span class="item">N° da Cota</span>
        <span class="item">Prêmio</span>
      </div>
      <div *ngFor="let item of information; let i = index"
      [ngClass]="i % 2 === 0 ? 'green-row tbody' : 'black-row tbody'">
        <div class="content item">
          <div style="display: flex; align-items: center;"><img src="../../../../assets/images/star.png" alt="" /></div>
          <span>{{ item.nome }} {{ item.sobreNome }}</span>
        </div>
        <span class="item">
          <div class="winner">
            <div class="billar-group">
              <h3>{{ item.numeroBilhete }}</h3>
              <!-- <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[0] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[1] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[2] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[3] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[4] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[5] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[5] }}
                </span>
              </div>
              <div class="billar">
                <span>
                  {{ item.numeroBilhete.split('')[5] }}
                </span>
              </div> -->
            </div>
          </div>
        </span>
        <span class="payout item">+{{
          item.premio.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL"
          })
        }}</span>
      </div>
    </div>
  </div>
</div>
