import { Component, OnInit } from '@angular/core';
import { EnterpriseService } from './core/services/enterprise/enterprise.service';
import { ToastService } from './core/services/toast/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'rifa.ui';

  constructor(
    private enterpriseService: EnterpriseService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    document.title = '';
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
