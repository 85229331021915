<mat-card class="draw-card">
  <div class="row">
    <mat-card class="image-card" [ngStyle]="{'background-image': backgroundImage}"></mat-card>
    <div class="column">
      <h4>{{ drawName }}</h4>
      <div class="row-info-payment">
        <h6 class="draw">{{ drawCode }}</h6>
        <span class="chip-color" *ngIf="statusPayment === 'Pago'">{{ statusPayment }}</span>
        <span class="chip-color-pending" *ngIf="statusPayment === 'Pendente'">Pagamento {{ statusPayment }}</span>
        <span class="chip-color-cancel" *ngIf="statusPayment === 'Cancelado'">{{ statusPayment }}</span>
      </div>
    </div>
  </div>
</mat-card>
