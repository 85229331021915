import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';

@Component({
  selector: 'app-card-whatsapp',
  templateUrl: './card-whatsapp.component.html',
  styleUrls: ['./card-whatsapp.component.scss']
})
export class CardWhatsappComponent {

  @Output() whatsAppButton: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() telegramButton: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  //@Output() moreDrawsButton: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();

  @Input() data: any;
  @Input() index: any;
  @Input() isActive: boolean = false;
  @Input() cardTitle: string = '';
  @Input() cardSubtitle: string = '';

  constructor() { }

  ngOnInit() {
    
  }

  triggerWhatsAppButton(action: any) {
    this.whatsAppButton.emit(new TableActionEmitted(action, this.data, this.index));
  }

  triggerTelegramButton(action: any) {
    this.telegramButton.emit(new TableActionEmitted(action, this.data, this.index));
  }

  /* triggerMoreDrawsButton(action: any) {
    this.moreDrawsButton.emit(new TableActionEmitted(action, this.data, this.index));
  } */
}
