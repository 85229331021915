import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  
  private themeData: any;
  private pageBody: any = document.querySelector('html');
  
  constructor(
    @Inject(DOCUMENT) private _document: any,
  ) { }
  
  changeTheme(themeData: any) {
    Object.entries(themeData).map((entry: [string, any]) => {
      if (entry[1] && entry[1].value) {
        this.pageBody.style.setProperty(entry[1].variable, entry[1].value)
      }
    });
  }
}