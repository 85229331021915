<ng-container *ngIf="isActive; else finalizedText">
  <div class="container-whatsapp">
    <div [ngClass]="isActive == true ? 'whatsapp-group' : 'closed'">
      <h1>{{ cardTitle }}</h1>
      <button class="button-whats-app" *ngIf="data?.numeroWhatsapp !== null"
        (click)="triggerWhatsAppButton($event)">
        <span>Participe do Grupo no Whats-App</span>
      </button>
      <button class="button-telegram"  *ngIf="data?.numeroTelegram !== null"
        (click)="triggerTelegramButton($event)">
        <span>Participe do Grupo no Telegram</span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #finalizedText>
  <div class="container-whatsapp">
    <div [ngClass]="isActive == true ? 'whatsapp-group' : 'closed'">
        <span>Encerrado</span>
      <h1>{{ cardTitle }}</h1>
      <!-- <p [innerHTML]>{{ cardSubtitle }}</p> -->
      <div [innerHTML]="cardSubtitle"></div>
      <button (click)="triggerWhatsAppButton($event)">
        <span>Ver Resultado</span>
      </button>
    </div>
    <!-- <button class="more-draws" (click)="triggerMoreDrawsButton($event)">
        <span>Conferir Sorteios Ativos</span>
    </button> -->
    <a class="see-regulation" href="#">Ver Regulamento</a>
  </div>
</ng-template>
