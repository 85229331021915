<div class="center-card">
  <div class="container">
    <app-sorteio-ativo-card
      style="max-width: 850px; width: 100%;"
      [backgroundImage]="mainBackgroundImage"
      [isActive]="false">
    </app-sorteio-ativo-card>
    <div class="select-image">
      <img [src]="activeImage1" (click)="changeImageInCard(1)" alt="Imagem sorteio 01" />
      <img [src]="activeImage2" (click)="changeImageInCard(2)" alt="Imagem sorteio 02" />
      <img [src]="activeImage3" (click)="changeImageInCard(3)" alt="Imagem sorteio 03" />
    </div>
    <app-card-whatsapp
      [isActive]="false"
      (whatsAppButton)="navigateToFederalLottery()"
      (moreDrawsButton)="navigateToAllDraws()"
      [cardTitle]="this.finalizedDraw?.titulo"
      [cardSubtitle]="this.finalizedDraw?.descricao">
    </app-card-whatsapp>
  </div>
  <span class="details">Detalhes do Sorteio</span>
  <div class="winner">
    <div class="number-win">
      <h1>Número <br> Ganhador</h1>
      <span class="separator"></span>
    </div>
    <div class="billar-group">
      <div class="billar">
        <span>
          {{ numberMainWinner?.split('')[0] }}
        </span>
      </div>
      <div class="billar">
        <span>
          {{ numberMainWinner?.split('')[1] }}
        </span>
      </div>
      <div class="billar">
        <span>
          {{ numberMainWinner?.split('')[2] }}
        </span>
      </div>
      <div class="billar">
        <span>
          {{ numberMainWinner?.split('')[3] }}
        </span>
      </div>
      <div class="billar">
        <span>
          {{ numberMainWinner?.split('')[4] }}
        </span>
      </div>
      <div class="billar">
        <span>
          {{ numberMainWinner?.split('')[5] }}
        </span>
      </div>
      <div class="billar" *ngIf="this.numberMainWinner !== undefined && this.numberMainWinner.length >= 7">
        <span>
          {{ numberMainWinner?.split('')[6] }}
        </span>
      </div>
      <div class="billar" *ngIf="this.numberMainWinner !== undefined && this.numberMainWinner.length === 8">
        <span>
          {{ numberMainWinner?.split('')[7] }}
        </span>
      </div>
    </div>
  </div>
  <app-table-finished-draw *ngIf="this.informationData"
    [information]="winnersAward">
  </app-table-finished-draw>
  <div class="row-order">
    <img src="../../../assets/images/tabler_medal.png" alt="Medalha" />
    <h4>Últimos Ganhadores</h4>
  </div>
  <div class="row winner-group" *ngIf="lastWinnersList.length > 0">
    <ng-container *ngFor="let winner of lastWinnersList; let i = index">
      <app-winner-image-card
        [backgroundImage]="winner.foto"
        [title]="winner.tituloResgate">
      </app-winner-image-card>    
    </ng-container>
  </div>
  <div *ngIf="lastWinnersList.length === 0">
    <mat-card class="no-winners-card">
      <mat-card-content>
        <h2>Não Apresenta Novos Ganhadores Até o Momento</h2>
      </mat-card-content>
    </mat-card>
  </div>
</div>
