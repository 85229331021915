import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { DataService } from 'src/app/core/services/data-service/data.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { BuyService } from 'src/app/core/services/buy/buy.service';
@Component({
  selector: 'app-inicial-page',
  templateUrl: './inicial-page.component.html',
  styleUrls: ['./inicial-page.component.scss']
})
export class InicialPageComponent implements OnInit{

  enterpriseData: any;
  progressValue: number = 65;

  mainData: any;
  mainBackgroundImage: any = "url('assets/images/no-image.png') no-repeat";
  mainTitle: string = '';
  mainDescription: string = '';
  mainWhatsapp: string = '';
  mainTelegram: string = '';

  userName: string = '';
  userEmail: string = '';
  clientOrders: any[] = [];
  
  sorteios: any = []
  sweepstakes: any;

  initialLoading: boolean = true;
  isLogged: boolean = false;

  lastWinnersList: any[] = [];

  differenceTime: number = 0;
  restingTime: string = '';

  //Images
  lastOrderImage1: any = "url('../../../assets/images/forno-eletrico.png')";
  lastOrderImage2: any = "url('../../../assets/images/geladeira.png')";
  lastOrderImage3: any = "url('../../../assets/images/pix-700.png')";

  constructor(
    private enterpriseService: EnterpriseService,
    private dataService: DataService,
    private authService: AuthService,
    private theme: ThemeService,
    private buyService: BuyService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.enterpriseData = this.dataService.getEnterpriseData();
    this.getSweepstakes();
    this.isAuthenticated(this.authService.isAuthenticated());
    this.getLastWinners();
  }

  opa(){
    this.theme.changeTheme({
      '--bg-papa': {
        variable: '--bg-papa',
        value: "url('/assets/images/creta-image-test-0.png') no-repeat"
      }
    })
  }

  navigateToDraw(event: any) {
    this.router.navigate([`/sorteio-ativo/${event.value.id}`]);
  }

  navigateToActiveDraw(id: string): void {
    this.router.navigate([`/sorteio-ativo/${id}`]);
  }

  navigateToWhatsapp() {
    window.open(`${this.mainWhatsapp}`);
  }

  navigateToTelegram() {
    window.open(`${this.mainTelegram}`);
  }

  navigateToAllDraw() {
    this.router.navigate([`/todos-sorteios`]);
  }

  navigateToLegalTerms() {
    this.router.navigate([`/termos-legais`]);
  }

  private isAuthenticated(auth: boolean): void {
    if (auth) {
      this.getBuyClient();
      this.userName = this.authService.userName();
      this.userEmail = this.authService.userEmail();
      this.isLogged = true;
    } else {
      this.isLogged = false;      
    }
  }

  private getSweepstakes() {
    const enterpriseId = this.enterpriseData.id;
    this.enterpriseService.getOnlyActiveSweepstakes(enterpriseId).subscribe({
      next: this.treatSweepstakesSuccessfull,
      error: this.treatInitialPageErrorAtAll
    });
  }

  private getBuyClient() {
    this.buyService.getBuyClient().subscribe({
      next: this.treatBuyClientSuccessfull,
      error: this.treatInitialPageErrorAtAll
    });
  }

  private getLastWinners() {
    const enterpriseId = this.enterpriseData.id;
    this.enterpriseService.getLastWinners(enterpriseId).subscribe({
      next: this.treatLoadLastWinnersSuccessfull,
      error: this.treatInitialPageErrorAtAll
    });
  }

  private treatSweepstakesSuccessfull = (res: any): void => {
    if (res && res.sorteios && res.sorteios.length > 0) {
      this.dataService.setSweepstakesData(res.sorteios);
      let sorteios = res.sorteios;
      sorteios.map((sorteio: any) => {
        if (sorteio.principal === true && sorteio.statusSorteio === 'Ativo') {
          this.updateMainCard(sorteio);
        } else if (sorteio.statusSorteio === 'Ativo') {
          this.sorteios.push(sorteio);
        }
      });
    }
    this.initialLoading = false;
  }

  private treatBuyClientSuccessfull = (res: any): void => {
    res.sort((a: any, b: any) => {
      const dateA = new Date(a.dataCompra).getTime();
      const dateB = new Date(b.dataCompra).getTime();
      return dateB - dateA;
    });
    res.map((order: any) => this.clientOrders.push(order));
    this.lastOrderImage1 = `url(${this.clientOrders[0]?.dadosSorteio?.imagemSorteio})`;
    this.lastOrderImage2 = `url(${this.clientOrders[1]?.dadosSorteio?.imagemSorteio})`;
    this.lastOrderImage3 = `url(${this.clientOrders[2]?.dadosSorteio?.imagemSorteio})`;
  }

  private updateMainCard = (sorteio: any): void => {
    this.mainData = sorteio;
    sorteio.imagens.map((image: any) => image.posicao === 1 ? this.mainBackgroundImage = `url(${image.imagem}) no-repeat` : this.mainBackgroundImage);
    this.mainTitle = sorteio.titulo;
    this.mainDescription = sorteio.descricao;
    this.mainWhatsapp = sorteio.numeroWhatsapp;
    this.mainTelegram = sorteio.numeroTelegram;
    
    let drawDate = new Date(sorteio?.dataSorteio);
    setInterval(() => {
      let difference = drawDate.getTime() - new Date().getTime();
      this.restingTime = this.formatTime(difference);
    }, 1000);
  }

  private formatTime(differenceTime: number): string {
    let seconds = Math.floor((differenceTime / 1000) % 60);
    let minuts = Math.floor((differenceTime / (1000 * 60)) % 60);
    let hours = Math.floor((differenceTime / (1000 * 60 * 60)) % 24);
    let days = Math.floor((differenceTime / (1000 * 60 *60 * 24)));
    return `${days.toString().padStart(2, '0')}d ${hours.toString().padStart(2, '0')}h ${minuts.toString().padStart(2, '0')}min ${seconds.toString().padStart(2, '0')}s`;
  }

  private treatLoadLastWinnersSuccessfull = (res: any): void => {;
    res.map((winner: any) => this.lastWinnersList.push(winner));
  }

  private treatInitialPageErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
