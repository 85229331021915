<app-fullscreen-loading
  [visible]="initialLoading">
</app-fullscreen-loading>

<div *ngIf="!initialLoading">
  <app-toolbar
    [data]="data"
    [logoImage]="logoImage">
  </app-toolbar>
  <router-outlet></router-outlet>
  <app-footer
    [logoImageFooter]="logoImage"
    [facebook]="facebook"
    [instagram]="instagram"
    [kwai]="kwai"
    [tiktok]="tiktok"
    [telefoneContato]="telefoneContato"
    (home)="navigateToHome()"
    (draw)="navigateToActiveDraw()"
    (profile)="navigateToProfile()"
    (winners)="navigateToWinners()"
    (contacts)="navigateToFAQ()">
  </app-footer>
</div>
