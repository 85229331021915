import { LegalTermsDialogComponent } from './../legal-terms-dialog/legal-terms-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BuyService } from 'src/app/core/services/buy/buy.service';

import { DataService } from 'src/app/core/services/data-service/data.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';

@Component({
  selector: 'app-checkout-draw',
  templateUrl: './checkout-draw.component.html',
  styleUrls: ['./checkout-draw.component.scss']
})
export class CheckoutDrawComponent implements OnInit{

  drawImage1: any = "../../../assets/images/image-moto.png";
  drawImage2: any = "../../../assets/images/ram.png";
  drawImage3: any = "../../../assets/images/dolar.png";

  drawId: string = '';
  cardTitle: string = 'Sorteio';

  quantityQuotes: number = 0;
  ticketValue: number = 0;
  freeTicket: number = 0;
  valueTotalQuotes: number = 0;

  allDraw: any[] = [];
  activeDraw: any;
  image: any;
  checkoutForm: any;

  packages: any[] = [];
  packagesToSend: any[] = [];
  packagesToShow: any[] = [];

  initialLoading: boolean = true;
  submitCheckoutLoading: boolean = false;
  allFieldsCompleted: boolean = false;

  constructor(
    private dataService: DataService,
    private buyService: BuyService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.drawId = params['idSorteio'];
      this.quantityQuotes = parseFloat(params['quantidadeCotas']);
      this.ticketValue = parseFloat(params['valorBilhete']);
      this.freeTicket = parseFloat(params['avulsoBilhete']);
      this.valueTotalQuotes = parseFloat(params['valorTotalCotas']);
      this.packagesToSend = JSON.parse(params['pacotes']);
      this.packagesToShow = JSON.parse(params['pacotesDetalhados']);
    });
    this.initialLoading = true;
    this.treatLoadDraws();
  }

  submitCheckoutData() {
    this.submitCheckoutLoading = true;
    let payload = {
      idSorteio: this.activeDraw.id,
      pacotes: this.packagesToSend,
      ...this.checkoutForm,
      valorCompra: this.valueTotalQuotes,
      quantidadeBilhete: this.quantityQuotes,
      dataNascimento: this.checkoutForm.dataNascimento.toISOString()
    }
    this.buyService.postBuy(payload, this.activeDraw.idEmpresa).subscribe({
      next: this.loadResponseCheckoutSuccessfull,
      error: this.treatCheckoutErrorAtAll
    });
  }

  openPixDialog(data?: any) {
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data,
      disableClose: true,
      maxWidth: '600px',
      maxHeight: '90vh',
      minWidth: '600px',
      minHeight: '60vh',
      panelClass: []
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => this.treatClosedDialog(res),
    })
  }

  updateTickets = (res: any): void => {
    if (this.quantityQuotes + res.quantityTickets > this.activeDraw.quantidadeMaximaBilhetes) {
      this.toastService.showToast('Quantidade máxima de bilhete excedida!', 'warning');
    } else {
      this.quantityQuotes += res.quantityTickets;
      this.valueTotalQuotes += res.valuePackage;
      this.packagesToSend.push({
        idPacote: res.packageId,
        valor: res.valuePackage,
      });

      let existingPackageToShow = this.packagesToShow.find(pkg => pkg.idPacote === res.packageId);
      if (existingPackageToShow) {
        existingPackageToShow.quantidade += 1;
      } else {
        this.packagesToShow.push({
          idPacote: res.packageId,
          valor: res.valuePackage,
          bilhetes: res.quantityTickets,
          quantidade: 1
        });
      }
    }
  }

  loadDataFromForm = (res: any): void => {
    if (res.nome !== null && res.nome !== '' && res.telefone !== null && res.telefone !== '' 
        && res.cpf !== null && res.cpf !== '' && res.email !== null && res.email !== '' 
        && res.dataNascimento !== '') {
      this.allFieldsCompleted = true;
      this.checkoutForm = res;
    } else {
      this.allFieldsCompleted = false;
    }
  }

  openNewTab(route: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  private treatLoadDraws() {
    if (this.dataService.getSweepstakesData() !== null || undefined) {
      this.dataService.getSweepstakesData().map((draw: any) => {
        if (draw.statusSorteio === 'Ativo') {
          this.allDraw.push(draw);
        }
      });
      this.activeDraw = this.allDraw.find((draw: any) => draw.id === this.drawId);
      this.allDraw = this.allDraw.filter((draw: any) => draw.id !== this.drawId);
    }
    this.activeDraw.pacotes.map((pacote: any) => {
      if (pacote.tipoPacote === 2) {
        this.packages.push(pacote);
      }
    });
    this.treatDrawData(this.activeDraw);
  }

  private treatDrawData (drawData: any) {
    this.cardTitle = drawData.titulo;
    drawData.imagens.map((img: any) => img.posicao === 1 ? this.image = img.imagem : null);
    this.initialLoading = false;
  }

  private treatClosedDialog = (res: any): void => {}

  private loadResponseCheckoutSuccessfull = (res: any) => {
    this.openPixDialog({
      payment: res,
      valorCompra: this.valueTotalQuotes,
      quantidadeBilhete: this.quantityQuotes,
      numeroWhatsapp: this.activeDraw.numeroWhatsapp,
      numeroTelegram: this.activeDraw.numeroTelegram
    });
    this.submitCheckoutLoading = false;
  }

  private treatCheckoutErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
    this.submitCheckoutLoading = false;
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
