<div class="container center-card">

  <mat-card class="form">
    <div class="row user-group">
      <h1>Informações do Usuário</h1>
    </div>
    <form action="">
      <div class="row">
        <div class="input-wrapper">
          <label>
            <img src="../../../assets/images/user-icon.png" alt="" />
            Nome Completo
          </label>
          <input
            type="text"
            [value]="firstName"
            [disabled]="true"/>
        </div>
        <!-- <div class="input-wrapper">
          <label>
            <img src="../../../assets/images/user-icon.png" alt="" />
            Sobrenome
          </label>
          <input
            type="text"
            [value]="lastName"
            [disabled]="true"/>
        </div> -->
      </div>

      <div class="row">
        <div class="input-wrapper">
          <label>
            <img src="../../../assets/images/cpf-icon.png" alt="" />
            CPF
          </label>
          <input
            type="text"
            [value]="cpfCnpj"
            [disabled]="true"/>
        </div>
        <div class="input-wrapper">
          <label>
            <img src="../../../assets/images/email-icon.png" alt="" />
            E-mail
          </label>
          <input
            type="email"
            [value]="email"
            [disabled]="true"/>
        </div>
      </div>

      <div class="row">
        <div class="input-wrapper">
          <label>
            <img src="../../../assets/images/phone-icon.png" alt="" />
            Número de Telefone
          </label>
          <input
            type="tel"
            [value]="phoneNumber"
            [disabled]="true"/>
        </div>
        <div class="input-wrapper"></div>
      </div>

      <div class="row button-group">
        <div></div>
        <button class="save"
          (click)="logout()">
          <span>Sair</span>
        </button>
      </div>
    </form>
  </mat-card>

  <div class="overflow">
    <h1>Minhas Compras</h1>
    <div class="table">
      <div *ngFor="let item of userOrders | paginate: { id: 'unique-pagination-id', itemsPerPage: pageSize, currentPage: currentPage, totalItems: userOrders.length }; let i = index" class="tbody">
        <mat-expansion-panel
          [disabled]="item.statusCompra === 'Cancelado' ? true : false"
          (opened)="item.statusCompra === 'Pago' ? togglePanel(item.id) : null">
          <mat-expansion-panel-header>
            <div class="row-order">
              <div class="order-info">
                <img [src]="item?.dadosSorteio?.imagemSorteio">
                <div class="order-column">
                  <h2>{{ item?.dadosSorteio?.titulo }}</h2>
                  <h3>#{{ item?.numeroCompra }}</h3>
                </div>
              </div>
              <div class="item bold">
                {{ item?.quantidadeBilhete }} x Cotas
              </div>
              <div class="item bold">
                {{ item.valorCompra.toLocaleString("pt-br", {style: "currency",currency: "BRL"}) }}
              </div>
              <div class="item-status">
                <span class="status item" *ngIf="item.statusCompra === 'Pendente'">
                  <span>Pagamento Pendente</span>
                </span>
                <span class="status-green item" *ngIf="item.statusCompra === 'Pago'">
                  <span>Pagamento Efetuado</span>
                </span>
                <span class="status-cancel item" *ngIf="item.statusCompra === 'Cancelado'">
                  <span>Cancelado</span>
                </span>
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="numbers-container" *ngIf="item.statusCompra === 'Pago'">
            <div *ngFor="let column of item.listNumbers; let i = index" class="number-column">
              <div *ngFor="let number of column">
                <div class="row">
                  <mat-icon>confirmation_number</mat-icon>
                  <h3>{{ number.numeroBilhete }}</h3>
                </div>                
              </div>
            </div>
          </div>
          <div class="pix-container" *ngIf="item.statusCompra === 'Pendente'">
            <div class="column">
              <qr-code
                [value]="item.urlPix"
                size="200"
                errorCorrectionLevel="M">
              </qr-code>
              <h3>Expira em: {{ item.validadePix | date: 'dd/MM/yyyy HH:mm:ss'}}</h3>
            </div>
            <div class="column">
              <div class="pix-key">
                <h2 class="pix-text">
                  {{ item.urlPix }}
                </h2>
              </div>
              <div class="button-inside">
                <button class="copy-button"
                  (click)="copyText(item.urlPix)">
                  <mat-icon class="icon-copy">content_copy</mat-icon>
                  <h5>Copiar Código Pix</h5>
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
      <pagination-controls class="pagination-spreads"
        id="unique-pagination-id"
        (pageChange)="pageChanged($event)"
        previousLabel="Anterior"
        nextLabel="Próximo">
      </pagination-controls>
    </div>
  </div>
</div>
