import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-winner-image-card',
  templateUrl: './winner-image-card.component.html',
  styleUrls: ['./winner-image-card.component.scss']
})
export class WinnerImageCardComponent implements OnInit{

  @Input() backgroundImage: any;
  @Input() title: string = '';

  constructor() { }

  ngOnInit() {
    this.backgroundImage = `url(${this.backgroundImage}) no-repeat`
  }
}
