import { Component, Output, EventEmitter, Input } from '@angular/core';

import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';

interface ITickets {
  id: number;
  price: number;
  quantity: number;
  chance: string;
}

@Component({
  selector: 'app-complete-your-order',
  templateUrl: './complete-your-order.component.html',
  styleUrls: ['./complete-your-order.component.scss']
})
export class CompleteYourOrderComponent {

  @Output() openPixDialog: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() plusCountTicket: EventEmitter<any> = new EventEmitter<any>();

  @Input() packages: any[] = [];
  @Input() verifyFields: boolean = false;
  tickets: ITickets[] = []

  checkboxValidator: boolean = false;

  constructor(
    private enterpriseService: EnterpriseService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    
  }
  
  toggleCheckbox(event: any) {
    this.checkboxValidator = event.target.checked;
  }

  triggerOpenPixDialog(action: any) {
    if (this.checkboxValidator === true) {
      if (this.verifyFields) {
        this.openPixDialog.emit(action);
      } else {
        this.toastService.showToast('Preencha todos os campos corretamente!', 'warning');
      }
    } else {
      this.toastService.showToast('Concorde com os termos de uso e Preencha todos os campos!', 'warning');
    }
  }

  buttonPlus(quantityTickets: number, valuePackage: number, packageId: string) {
    this.plusCountTicket.emit({quantityTickets: quantityTickets, valuePackage: valuePackage, packageId: packageId});
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
