<app-fullscreen-loading
  [visible]="initialLoading">
</app-fullscreen-loading>

<div class="spinner-data" *ngIf="submitCheckoutLoading">
  <mat-progress-spinner
    class="spinner"
    diameter="80"
    color="warn"
    mode="indeterminate">
  </mat-progress-spinner>
</div>

<div class="container center-card" *ngIf="!initialLoading">
  <app-checkout-draw-primary-card
    (takeCheckoutForm)="loadDataFromForm($event)"
    [cardTitle]="cardTitle"
    [quantity]="quantityQuotes"
    [unitPrice]="ticketValue"
    [freeTicket]="freeTicket"
    [total]="valueTotalQuotes"
    [packagesToShow]="packagesToShow"
    [image]="image">
  </app-checkout-draw-primary-card>
  <div class="navbar">
    <a class="money-bag" href="#">+  Aumentar Minhas Chances</a>
  </div>
  <app-complete-your-order
    (openPixDialog)="submitCheckoutData()"
    (plusCountTicket)="updateTickets($event)"
    [packages]="packages"
    [verifyFields]="allFieldsCompleted">
  </app-complete-your-order>
  <div class="row-order">
    <img src="../../../assets/images/tabler_medal.png" alt="Medalha">
    <h4>Recomendados Para Você</h4>
  </div>
  <div class="draw-card-container">
    <ng-container *ngFor="let sorteio of allDraw; let i = index">
      <ng-container *ngIf="i >= 0">
        <app-middle-draw-card
          [data]="sorteio"
          (clickToDraw)="openNewTab('/sorteio-ativo/' + sorteio.id)">
        </app-middle-draw-card>
      </ng-container>
    </ng-container>
  </div>
</div>
