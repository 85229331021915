import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-draws-card',
  templateUrl: './all-draws-card.component.html',
  styleUrls: ['./all-draws-card.component.scss']
})
export class AllDrawsCardComponent  implements OnInit{

  @Input() data: any;

  drawDate: Date = new Date;
  differenceTime: number = 0;
  restingTime: string = '';
  backgroundImage: any;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.drawDate = new Date(this.data?.dataSorteio)
    setInterval(() => {
      let difference = this.drawDate.getTime() - new Date().getTime();
      this.differenceTime = difference;
      this.data?.sorteio !== undefined ? this.restingTime = this.formatTime(this.differenceTime) : this.restingTime = '';
      //this.restingTime = this.formatTime(this.differenceTime);
    }, 1000);
    this.data?.imagens.map((image: any) => {
      if (image.posicao === 1) {
        this.backgroundImage = `url('${image.imagem}') no-repeat`;
      }
    });
  }

  navigateToActiveDraw(): void {
    this.router.navigate([`/sorteio-ativo/${this.data?.id}`]);
  }

  private formatTime(differenceTime: number): string {
    let seconds = Math.floor((differenceTime / 1000) % 60);
    let minuts = Math.floor((differenceTime / (1000 * 60) % 60));
    let hours = Math.floor((differenceTime / (1000 * 60 * 60)) % 24);
    let days = Math.floor((differenceTime / (1000 * 60 * 60 * 24)));
    return `${days.toString().padStart(2, '0')}d ${hours.toString().padStart(2, '0')}h ${minuts.toString().padStart(2, '0')}min ${seconds.toString().padStart(2, '0')}s`;
  }
}
