<div class="container center-card">
  <app-all-draws-card *ngIf="dataLoaded"
    style="max-width: 1800px; width: 100%"
    [data]="mainDraw"
  ></app-all-draws-card>
  <div class="draw-container">
    <span class="title">Sorteios</span>
    <div class="draw-navbar">
      <div class="draw-item" [ngClass]="{'draw-item-green': selectedTab === 'ativos'}" (click)="selectedTab = 'ativos'">
        <span class="change-color" *ngIf="selectedTab === 'ativos'"> Ativos </span>
        <span *ngIf="selectedTab !== 'ativos'"> Ativos </span>
        <span class="number">
          {{ activeDraws.length }}
        </span>
      </div>
      <div class="draw-item" [ngClass]="{'draw-item-green': selectedTab === 'finalizados'}" (click)="selectedTab = 'finalizados'">
        <span class="change-color" *ngIf="selectedTab === 'finalizados'"> Finalizados </span>
        <span *ngIf="selectedTab !== 'finalizados'"> Finalizados </span>
        <span class="number">
          {{ finishedDraws.length }}
        </span>
      </div>
      <div class="draw-item" [ngClass]="{'draw-item-green': selectedTab === 'proximos'}" (click)="selectedTab = 'proximos'">
        <span class="change-color" *ngIf="selectedTab === 'proximos'"> Próximos </span>
        <span *ngIf="selectedTab !== 'proximos'"> Próximos </span>
        <span class="number">
          {{ upcomingDraws.length }}
        </span>
      </div>
    </div>
    <div *ngIf="selectedTab === 'ativos'" class="cards">
      <div class="card" *ngFor="let item of activeDraws">
        <h1>{{ item?.titulo }}</h1>
        <div class="card-content">
          <!-- <span>{{ item?.descricao }}</span> -->
          <div [innerHTML]="item?.descricao"></div>
          <div>
            <mat-icon>info</mat-icon>
          </div>
        </div>
        <div class="card-footer">
          <span>
            <mat-icon>monetization_on</mat-icon>
            <h2>R$ {{ item?.valorBilhete.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h2>
          </span>
          <button class="navigate-button-card"
            (click)="navigateToActiveDraw(item)">Comprar</button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedTab === 'finalizados'" class="cards-finalized">
      <div class="card" *ngFor="let item of finishedDraws">
        <h1>{{ item?.titulo }}</h1>
        <div class="card-content">
          <!-- <span>{{ item?.descricao }}</span> -->
          <div [innerHTML]="item?.descricao"></div>
          <mat-icon>info</mat-icon>
        </div>
        <div class="card-footer">
          <span>
            <mat-icon>monetization_on</mat-icon>
            <h2>R$ {{ item?.valorBilhete.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h2>
          </span>
          <button
            (click)="navigateToFinalizedDraw(item)">Ver Resultado</button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedTab === 'proximos'" class="cards-next">
      <div class="card" *ngFor="let item of upcomingDraws">
        <h1>{{ item?.titulo }}</h1>
        <div class="card-content">
          <!-- <span>{{ item?.descricao }}</span> -->
          <div [innerHTML]="item?.descricao"></div>
          <mat-icon>info</mat-icon>
        </div>
        <div class="card-footer">
          <span>
            <mat-icon>monetization_on</mat-icon>
            <h2>R$ {{ item?.valorBilhete.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h2>
          </span>
          <button
            [disabled]="true">
            Aguarde</button>
        </div>
      </div>
    </div>
  </div>
</div>
