<div class="container">
  <div class="row-title">
    <h2>Sua Compra</h2>
    <button class="button-title" (click)="backToInitialPage()"><mat-icon>close</mat-icon></button>
  </div>
  <mat-card class="info-card">
    <div class="row-card-info">
      <h6>N° da Compra</h6>
      <h6># {{ paymentData.numeroCompra }}</h6>
    </div>
    <div class="row-card-info">
      <h3>Quantidade</h3>
      <div class="row">
        <h3 class="number">{{ data?.quantidadeBilhete }} x Cotas</h3>
      </div>
    </div>
    <div class="row-card-info">
      <mat-chip>
        <h6 class="chip">Pagamento Pendente</h6>
      </mat-chip>
      <h4>R$ {{ data?.valorCompra.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h4>
    </div>
  </mat-card>
  <mat-card class="text-card">
    <h4 class="text-card-font">Realize o Pagamento utilizando o QR-Code abaixo ou o Pix Copia e Cola,
      após o pagamento o status será alterado para o pagamento realizado, confira
      seu e-mail para detalhes das cotas adquiridas.
    </h4>
  </mat-card>
  <div class="row-payment-data">
    <qr-code
      [value]="qrData"
      size="140"
      errorCorrectionLevel="M">
    </qr-code>
    <!-- <img class="qr-code" src="../../../assets/images/qrcode.png"> -->
    <div class="pix-key">
      <h2 class="pix-text">
        {{ paymentData?.transacao?.urlPix }}
      </h2>
    </div>
  </div>
  <div class="button-inside">
    <button class="copy-button"
      (click)="copyText()">
      <mat-icon class="icon-copy">content_copy</mat-icon>
      <h5>Copiar Código Pix</h5>
    </button>
  </div>
  <div class="row-social-midia">
    <h3>Partice dos nossos grupos:</h3>
  </div>
  <div class="row-title">
    <div class="button-inside-social-midia">
      <button class="copy-button-whatsapp"
        (click)="navigateToWhatsapp()">
        <img class="img-size-logo" src="../../../assets/images/logo-whatsapp.png">
        <h5>Participar do Grupo no WhatsApp</h5>
      </button>
    </div>
  </div>
  <div class="row-title">
    <div class="button-inside-social-midia">
      <button class="copy-button-telegram"
        (click)="navigateToTelegram()">
        <img class="img-size-logo" src="../../../assets/images/telegram-icon.png">
        <h5>Participar do Grupo no Telegram</h5>
      </button>
    </div>
  </div>
</div>