import { Component, OnInit, Input } from '@angular/core';

import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

interface ITable {
  id: number;
  quotaNumber: string;
  status: string;
  userWin: string;
  payout: number;
}

@Component({
  selector: 'app-table-finished-draw',
  templateUrl: './table-finished-draw.component.html',
  styleUrls: ['./table-finished-draw.component.scss']
})
export class TableFinishedDrawComponent implements OnInit {

  @Input() information: any;

  data: ITable[] = [];

  constructor(
    private enterpriseService: EnterpriseService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.getTable;
  }

  get getTable() {
    return this.enterpriseService.getDataTable().subscribe({
      next: (res: ITable[]) => {
        this.data = res;
      },
      error: (err: any) => {
        this.toastService.showToast(this.parseError(err), 'danger');
      }
    })
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
