import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';

import { LayoutModule } from '@angular/cdk/layout';
import { NgxMaskPipe,NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { InPageToastComponent } from './components/in-page-toast/in-page-toast.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainHomeCardComponent } from './components/main-home-card/main-home-card.component';
import { LatestOrdersComponent } from './components/latest-orders/latest-orders.component';
import { MiddleDrawCardComponent } from './components/middle-draw-card/middle-draw-card.component';
import { WinnerImageCardComponent } from './components/winner-image-card/winner-image-card.component';
import { SorteioAtivoCardComponent } from './components/sorteio-ativo-card/sorteio-ativo-card.component';
import { CardWhatsappComponent } from './components/card-whatsapp/card-whatsapp.component';
import { BuyTicketComponent } from './components/buy-ticket/buy-ticket.component';
import { TableDrawComponent } from './components/table-draw/table-draw.component';
import { AllDrawsCardComponent } from './components/all-draws-card/all-draws-card.component';
import { CheckoutDrawPrimaryCardComponent } from './components/checkout-draw-primary-card/checkout-draw-primary-card.component';
import { CompleteYourOrderComponent } from './components/complete-your-order/complete-your-order.component';
import { HowWorkCardComponent } from './components/how-work-card/how-work-card.component';
import { TableFinishedDrawComponent } from './components/table-finished-draw/table-finished-draw.component';
import { FullscreenLoadingComponent } from './components/fullscreen-loading/fullscreen-loading.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatCardModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatGridListModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressBarModule,
    MatExpansionModule,
    LayoutModule
  ],
  declarations: [
    ToolbarComponent,
    InPageToastComponent,
    FooterComponent,
    MainHomeCardComponent,
    SorteioAtivoCardComponent,
    LatestOrdersComponent,
    TableFinishedDrawComponent,
    MiddleDrawCardComponent,
    WinnerImageCardComponent,
    CompleteYourOrderComponent,
    CardWhatsappComponent,
    BuyTicketComponent,
    CheckoutDrawPrimaryCardComponent,
    AllDrawsCardComponent,
    TableDrawComponent,
    HowWorkCardComponent,
    FullscreenLoadingComponent
  ],
  exports: [
    ToolbarComponent,
    InPageToastComponent,
    FooterComponent,
    LatestOrdersComponent,
    MainHomeCardComponent,
    SorteioAtivoCardComponent,
    BuyTicketComponent,
    CompleteYourOrderComponent,
    MiddleDrawCardComponent,
    CardWhatsappComponent,
    CheckoutDrawPrimaryCardComponent,
    WinnerImageCardComponent,
    TableDrawComponent,
    AllDrawsCardComponent,
    HowWorkCardComponent,
    TableFinishedDrawComponent,
    FullscreenLoadingComponent,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatGridListModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatCardModule,
    MatIconModule,
    LayoutModule,
  ],
  providers: [
    provideNgxMask()
  ],
})
export class SharedModule { }
