export class Mock {
  static Ticket() {
    return [
      {
        id: 1,
        chance: "3%",
        quantity: 25,
        price: 37.25,
      },
      {
        id: 2,
        chance: "8%",
        quantity: 50,
        price: 74.50,
      },
      {
        id: 3,
        chance: "12%",
        quantity: 100,
        price: 129.00,
      },
      {
        id: 4,
        chance: "18%",
        quantity: 200,
        price: 258.00,
      }
    ]
  }
  static Table() {
    return [
      {
        id: 1,
        quotaNumber: "333333",
        status: "Resgatado",
        userWin: "Byd1523",
        payout: 2200
      },
      {
        id: 2,
        quotaNumber: "123456",
        status: "Pendente",
        userWin: "---------",
        payout: 1200
      },
      {
        id: 3,
        quotaNumber: "111111",
        status: "Pendente",
        userWin: "---------",
        payout: 3200
      },
      {
        id: 4,
        quotaNumber: "222222",
        status: "Pendente",
        userWin: "---------",
        payout: 12200
      },
    ]
  }
  static Draws() {
    return [
      {
        id: 1,
        name: "Sorteio Hyundai Creta Ultimate",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "ativo"
      },
      {
        id: 2,
        name: "Sorteio Moto Kawasaki XRL8",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "ativo"
      },
      {
        id: 3,
        name: "Sorteio Dodge Ram",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "ativo"
      },
      {
        id: 4,
        name: "Sorteio R$ 2.000 no Pix",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "ativo"
      },
      {
        id: 5,
        name: "Sorteio R$ 1.000 no Pix",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "ativo"
      },
      {
        id: 6,
        name: "Sorteio R$ 3.000 no Pix",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "ativo"
      },
      {
        id: 7,
        name: "Sorteio Hyundai Creta Ultimate",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "finalizado"
      },
      {
        id: 8,
        name: "Sorteio Moto Kawasaki XRL8",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "finalizado"
      },
      {
        id: 9,
        name: "Sorteio Dodge Ram",
        description: "Lorem Impsum Dolor",
        price: 1.90,
        status: "proximo"
      },
    ]
  }
  static Requests() {
    return [
      {
        id: 1,
        name: "Forno Elétrico",
        ticket: 234567,
        qnt: 25,
        priceTicket: 1.75,
        price: 43.50,
        isPaid: true,
      },
      {
        id: 2,
        name: "Geladeira",
        ticket: 234567,
        qnt: 25,
        priceTicket: 1.75,
        price: 43.50,
        isPaid: true,
      },
      {
        id: 3,
        name: "Air Fryer",
        ticket: 234567,
        qnt: 25,
        priceTicket: 1.75,
        price: 43.50,
        isPaid: true,
      },
      {
        id: 4,
        name: "Microondas",
        ticket: 234567,
        qnt: 25,
        priceTicket: 1.75,
        price: 43.50,
        isPaid: false,
      },
    ]
  }
}
