import { LegalTermsDialogComponent } from './pages/legal-terms-dialog/legal-terms-dialog.component';
import { LegalTermsComponent } from './pages/legal-terms/legal-terms.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InicialPageComponent } from './pages/inicial-page/inicial-page.component';
import { ActiveDrawComponent } from './pages/active-draw/active-draw.component';
import { DrawFinalizedComponent } from './pages/draw-finalized/draw-finalized.component';
import { CheckoutDrawComponent } from './pages/checkout-draw/checkout-draw.component';
import { AllDrawsComponent } from './pages/all-draws/all-draws.component';
import { UserOrderComponent } from './pages/user-order/user-order.component';
import { HomeComponent } from './container/home/home.component';

import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { LayoutModule } from '@angular/cdk/layout';
import { CarouselModule } from 'primeng/carousel';

import { PaymentDialogComponent } from './pages/payment-dialog/payment-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { QrCodeModule } from 'ng-qrcode';
import { LoginDialogComponent } from './pages/login-dialog/login-dialog.component';
import { UserLinkAuthComponent } from './pages/user-link-auth/user-link-auth.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    InicialPageComponent,
    UserSettingsComponent,
    ActiveDrawComponent,
    DrawFinalizedComponent,
    CheckoutDrawComponent,
    AllDrawsComponent,
    UserOrderComponent,
    HomeComponent,
    PaymentDialogComponent,
    LoginDialogComponent,
    UserLinkAuthComponent,
    FaqComponent,
    LegalTermsComponent,
    PrivacyComponent,
    LegalTermsDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    MatCardModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatGridListModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatChipsModule,
    MatProgressBarModule,
    MatExpansionModule,
    CarouselModule,
    LayoutModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPaginationModule,
    QrCodeModule,
  ],
  exports: [
    MatDialogModule,
  ],
  providers: [
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
