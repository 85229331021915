import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';

@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.scss']
})
export class UserOrderComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {

  }

  openPixDialog(data?: any) {
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data,
      disableClose: true,
      maxWidth: '600px',
      width: '100%',
      maxHeight: '90vh',
      minHeight: '570px',
      panelClass: []
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => this.treatClosedDialog(res),
    });
  }

  private treatClosedDialog = (res: any): void => {
    //Insert close function dialog here
  }
}
