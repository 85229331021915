<mat-card class="how-work-card">
  <div class="row-how-work">
      <h2>Como Trabalhamos?</h2>
      <img class="change-image-size" src="../../../../assets/images/Frame_307.svg">
      <h3>Acompanhe nosso processo interno e fique sabendo um pouco mais da nossa atuação!!</h3>
  </div>
</mat-card>
<div class="divider-header"></div>
<div class="row-how-work space">
  <mat-card class="cards">
    <img src="../../../../assets/images/noto-v1_pool-8-ball.svg">
    <div class="column">
      <h4>Escolha o Sorteio</h4>
      <h5>Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Duis at euismod dui, et
          euismod tortor. In fermentum, eros eu
          egestas vulputate, urna ante scelerisque
          diam, id blandit lorem ex sit amet libero.</h5>
    </div>
  </mat-card>
  <div class="img-no-margin">
    <img class="lines" src="../../../../assets/images/Conection_lines_vertical.svg">
  </div>
  <mat-card class="cards -quotas">
    <img src="../../../../assets/images/emojione_admission-tickets.svg">
    <div class="column">
      <h4>Escolha Quantas Cotas</h4>
      <h5>Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Duis at euismod dui, et
        euismod tortor. In fermentum, eros eu
        egestas vulputate, urna ante scelerisque
        diam, id blandit lorem ex sit amet libero.</h5>
    </div>
  </mat-card>
</div>
<div class="img-no-margin">
  <img class="lines" src="../../../../assets/images/Conection_lines.svg">
</div>
<div class="row-how-work">
  <mat-card class="cards -realized">
    <img src="../../../../assets/images/emojione_admission-tickets.svg">
    <div class="column">
      <h4 class="change-color">Sorteio Realizado!</h4>
      <h5 class="change-color">Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Duis at euismod dui, et
        euismod tortor. In fermentum, eros eu
        egestas vulputate, urna ante scelerisque
        diam, id blandit lorem ex sit amet libero.</h5>
    </div>
  </mat-card>
  <div class="img-no-margin">
    <img class="lines" src="../../../../assets/images/Conection_lines_vertical.svg">
  </div>
  <mat-card class="cards">
    <img src="../../../../assets/images/Group_24.svg">
    <div class="column">
      <h4>Prêmio Entregue</h4>
      <h5>Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Duis at euismod dui, et
        euismod tortor. In fermentum, eros eu
        egestas vulputate, urna ante scelerisque
        diam, id blandit lorem ex sit amet libero.</h5>
    </div>
  </mat-card>
</div>
