<div class="container">
  <div class="card-container">
    <div class="card" *ngFor="let ticket of tickets; let i = index;">
      <div class="chance">
        <span>Aumente suas chances</span>
      </div>
      <h1>+{{ ticket.quantidadeBilhetes }} Und.</h1>
      <div class="price">
        <span>R$ {{ ticket.valorPacote.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</span>
        <button class="button-plus"
        (click)="insertQuantityCardTicket(ticket.quantidadeBilhetes, ticket.valorPacote, ticket.id)">
            <img src="../../../../assets/images/round-plus.png" alt="">
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="select-ticket">
    <span>Quantidade (Min. {{ data?.quantidadeMinimaBilhetes }})</span>
    <div>
      <span>Avulsos: R${{ data?.valorBilhete.toLocaleString('pt-br' , {minimumFractionDigits: 2}) }} x</span>
        <div>
          <button
            (click)="mineOneTicket()">
            <img src="../../../../assets/images/minus.png" alt="">
          </button>
          <input
            type="text"
            [value]="freeTickets">
          <button class="button-plus"
            (click)="plusOneTicket()">
            <img src="../../../../assets/images/plus.png" alt="">
          </button>
        </div>
    </div>
    <div class="package-container">
      <div class="pkg-info" *ngFor="let item of packagesToShow; let i = index">
        <h4>+{{ item.bilhetes }} Und.</h4>
        <h4>R${{ item.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}) }} x {{ item.quantidade }}</h4>
        <h5>=</h5>
        <h3>R${{ item.valor * item.quantidade }}</h3>
        <mat-icon
          (click)="removeQuantityCardTicket(item.bilhetes, item.valor, item.idPacote)">delete_seep</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
      <h3>Total de Bilhetes: {{ countTickets }}</h3>
      <span>R$ {{ priceTickets.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}/Total</span>
    </div>
    <button class="ensure"
      (click)="navigateToTicketDraw()"
      [disabled]="restingTickets">
      <span *ngIf="!restingTickets">Comprar!</span>
      <span *ngIf="restingTickets">Encerrado!</span>
    </button>
  </div> -->
</div>
<!-- <div class="column">
  <div *ngFor="let item of packagesToShow; let i = index">
    <h2>R${{ item.valor }}</h2>
    <h2>Quantidade de Cotas: {{ item.quantidade }}</h2>
    <h3>Subtotal{{ item.valor * item.quantidade}}</h3>
  </div>
  <h2>{{ subtotalPackages }}</h2>
</div> -->
