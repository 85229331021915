import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { BuyService } from 'src/app/core/services/buy/buy.service';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

interface IRequests {
  id: number;
  name: string;
  ticket: number;
  qnt: number;
  price: number;
  isPaid: boolean;
  priceTicket: number;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  requests: IRequests[] = [];

  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  cpfCnpj: string = '';
  email: string = '';

  userOrders: any[] = [];

  pageSize: number = 5;
  currentPage: number = 1;

  constructor(
    private enterpriseService: EnterpriseService,
    private authService: AuthService,
    private buyService: BuyService,
    private clipboard: Clipboard,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.getRequests();
    this.treatAuthData();
    if (this.authService.isAuthenticated()) {
      this.getBuyClient();
    }
  }

  backToHome() {
    this.router.navigate([`/`]);
  }

  logout() {
    this.authService.logout();
    this.router.navigate([`/`]);
  }

  copyText(data: any) {
    this.clipboard.copy(data);
    this.toastService.showToast('Chave pix Copiada com Sucesso', 'success');
  }

  getRequests() {
    return this.enterpriseService.getRequests().subscribe({
      next: (res: any) => {
        this.requests = res;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  togglePanel(orderId: string): void {
    this.userOrders.map((order: any) => {
      if(orderId === order.id){
        if (!order.listNumbers) {
          this.buyService.getBuyTicket(orderId).subscribe((res) => {
            order.listNumbers = res;
            order.listNumbers = this.splitArrayIntoChunks(order.listNumbers, 10);
          });
        }
      }
      return order;
    });
  }

  splitArrayIntoChunks(arr: any[], chunkSize: number): any[][] {
    arr.sort((a,b) => a.numeroBilhete.localeCompare(b.numeroBilhete, undefined, { numeric: true }));
    let result = [];
    for (let i = 0; i< arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  pageChanged(event: number): void {
    this.currentPage = event;
  }

  private treatAuthData() {
    this.firstName = this.authService.userName();
    this.phoneNumber = this.formatPhoneNumber(this.authService.userPhone());
    this.cpfCnpj = this.formatCpf(this.authService.cpfCnpjUser());
    this.email = this.authService.userEmail();
  }

  private getBuyClient() {
    this.buyService.getBuyClient().subscribe({
      next: this.treatOrdersClientSuccessfull,
      error: this.treatUserDataErrorAtAll
    });
  }

  private treatOrdersClientSuccessfull = (res: any): void => {
    res.map((order: any) => this.userOrders.push(order));
  }

  private treatUserDataErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private formatCpf(cpf: string): string {
    const cleanedCpf = cpf.replace(/\D/g, '');
    if (cleanedCpf.length !== 11) {
      return '';
    }
    return cleanedCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  private formatPhoneNumber(phone: string): string {
    const cleanedPhone = phone.replace(/\D/g, '');
    if (cleanedPhone.length === 10) {
      return cleanedPhone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else if (cleanedPhone.length === 11) {
      return cleanedPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else {
      return '';
    }
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
