<div class="center-card">
  <div class="form">
    <h1>Entrar</h1>
    <span>Faça seu login para ter acesso.</span>
    <div class="inputs-group">
      <div class="content">
        <label for="email">E-mail</label>
        <input type="email" placeholder="email@mail.com" id="email">
      </div>
      <div class="content">
        <label for="cpf">CPF</label>
        <input type="text" placeholder="000.000.000-00" id="cpf">
      </div>
      <button>Enviar</button>
    </div>
  </div>
</div>
