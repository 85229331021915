import { Component } from '@angular/core';

@Component({
  selector: 'app-in-page-toast',
  templateUrl: './in-page-toast.component.html',
  styleUrls: ['./in-page-toast.component.scss']
})
export class InPageToastComponent {

}
