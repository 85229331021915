import { Injectable } from '@angular/core';
import { parse, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  parseDate(dateString: string): Date {
    return parse(dateString, 'dd/MM/yyyy', new Date(), { locale: ptBR });
  }

  formatDate(date: Date): string {
    return format(date, 'dd/MM/yyyy', { locale: ptBR });
  }
}
