<mat-toolbar>
  <mat-toolbar-row class="primary-toolbar center-card">
    <img class="logo-image" [src]="logoImage" (click)="goToHome()">
    <div class="toolbar-button-container">
      <div class="toolbar-navigation">
        <h4 (click)="goToHome()">Início</h4>
        <h4 (click)="goToAllDraws()">Sorteios</h4>
        <h4 (click)="goToFAQ()">FAQ</h4>
      </div>
      <mat-icon *ngIf="isLogged" class="user-button" (click)="handleUserClick()">
        <div class="icon"></div>
      </mat-icon>
      <button *ngIf="!isLogged" class="login-button">Entrar</button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
