import { PrivacyComponent } from './pages/privacy/privacy.component';
import { LegalTermsComponent } from './pages/legal-terms/legal-terms.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './container/home/home.component';
import { ActiveDrawComponent } from './pages/active-draw/active-draw.component';
import { AllDrawsComponent } from './pages/all-draws/all-draws.component';
import { DrawFinalizedComponent } from './pages/draw-finalized/draw-finalized.component';
import { InicialPageComponent } from './pages/inicial-page/inicial-page.component';
import { UserOrderComponent } from './pages/user-order/user-order.component';
import { CheckoutDrawComponent } from './pages/checkout-draw/checkout-draw.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { LoginComponent } from './pages/login/login.component';
import { UserLinkAuthComponent } from './pages/user-link-auth/user-link-auth.component';
import { FaqComponent } from './pages/faq/faq.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: InicialPageComponent,
      },
      {
        path: 'sorteio-ativo/:id',
        component: ActiveDrawComponent,
      },
      {
        path: 'sorteio-finalizado/:id',
        component: DrawFinalizedComponent,
      },
      {
        path: 'todos-sorteios',
        component: AllDrawsComponent,
      },
      {
        path: 'checkout-draw',
        component: CheckoutDrawComponent,
      },
      {
        path: 'pedido-usuario',
        component: UserOrderComponent,
      },
      {
        path: 'user-settings',
        component: UserSettingsComponent,
      },
      {
        path: 'user-settings-auth/:email/:cpf',
        component: UserLinkAuthComponent,
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'termos-legais',
        component: LegalTermsComponent
      },
      {
        path: 'privacidade',
        component: PrivacyComponent
      }
    ]
  },
  {
    path: '*',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
