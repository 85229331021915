import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-terms-dialog',
  templateUrl: './legal-terms-dialog.component.html',
  styleUrls: ['./legal-terms-dialog.component.scss']
})
export class LegalTermsDialogComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<LegalTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {

  }

  backToCheckout(data?: any) {
    this.dialogRef.close(data);
  }
}
