<mat-card class="main-card" [ngStyle]="{'background': backgroundImage}"
  (click)="data?.statusSorteio === 'Ativo' ? triggerDraw($event) : triggerFinalizedDraw($event)">
  <mat-card-content>
    <mat-card class="inside-left-card">
      <mat-card-content>
        <div class="row">
          <button *ngIf="data?.statusSorteio === 'Ativo'" mat-button class="take-button" (click)="triggerDraw($event)">
            <h3>Quero Garantir o Meu!</h3>
          </button>
          <button *ngIf="data?.statusSorteio === 'Finalizado'" mat-button class="take-button" (click)="triggerFinalizedDraw($event)">
            <h3>Ver Resultado</h3>
          </button>
          <div class="div-card">
            <h3 class="weight-400" (click)="goToLegalTerms()">Ver Regulamento</h3>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="inside-right-card">
      <mat-card-content>
        <div class="column" *ngIf="data !== undefined">
          <h2>{{ cardRightTitle }}</h2>
          <!-- <div class="div-html" [innerHTML]="cardRightSubtitle"></div> -->
          <button mat-button class="participate-button" *ngIf="data?.numeroWhatsapp !== ''"
            (click)="triggerWhatsAppGroup($event)">
            <div class="row">
              <img class="img-size-logo" src="../../../../assets/images/logo-whatsapp.png">
              <h3 class="font-14">Participe do Grupo no WhatsApp</h3>
            </div>
          </button>
          <button mat-button class="participate-button-telegram" *ngIf="data?.numeroTelegram !== ''"
            (click)="triggerTelegramGroup($event)">
            <div class="row">
              <img class="img-size-logo" src="../../../../assets/images/telegram-icon.png">
              <h3 class="font-14">Participe do Grupo no Telegram</h3>
            </div>
          </button>
          <div class="dow-right-card" *ngIf="data?.exibirTemporizador">
            <div class="row" *ngIf="data?.statusSorteio === 'Ativo'">
              <mat-icon>timer</mat-icon>
              <h3>Acaba em: {{ restingTime }}</h3>
            </div>
            <div class="row" *ngIf="data?.statusSorteio === 'Finalizado'">
              <mat-icon>timer</mat-icon>
              <h3>Encerrado!</h3>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>