import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private snackbar: MatSnackBar,
  ) { }

  showToast(
    message: string,
    type: 'danger' | 'success' | 'warning' = 'success',
    duration: number = 5000
  ) {
    const toastOptions: MatSnackBarConfig = {
      duration,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: {danger: 'toast-danger', success: 'toast-success', warning: 'toast-warning'}[type]
    }
    this.snackbar.open(message, undefined, toastOptions);
  }
}
