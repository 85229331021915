import { Component, Input, OnInit } from '@angular/core';

import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { TicketsService } from 'src/app/core/services/tickets/tickets.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-table-draw',
  templateUrl: './table-draw.component.html',
  styleUrls: ['./table-draw.component.scss']
})
export class TableDrawComponent implements OnInit {

  @Input() information: any;
  @Input() description: any;

  data: any[] = []

  constructor(
    private enterpriseService: EnterpriseService,
    private ticketsService: TicketsService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.getTicketsAwards(this.information?.idEmpresa, this.information?.idSorteio);
    console.log(this.description);
  }

  private getTicketsAwards(enterpriseId: string, drawId: string) {
    this.ticketsService.getTicketsByEnterprise(enterpriseId, drawId).subscribe({
      next: this.loadTicketsSuccessfull,
      error: this.treatTableDrawErrorAtAll
    });
  }

  private loadTicketsSuccessfull = (res: any): void => {
    this.data = res;
  }

  private treatTableDrawErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
