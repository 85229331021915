<div class="container">
  <div class="container-inside">
    <div class="card" *ngFor="let ticket of packages">
      <div class="chance">
        <span>Aumente suas chances</span>
      </div>
      <h1>+{{ ticket.quantidadeBilhetes }} Und.</h1>
      <div class="price">
        <span>R$ {{ ticket.valorPacote.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</span>
        <button (click)="buttonPlus(ticket.quantidadeBilhetes, ticket.valorPacote, ticket.id)"><img src="../../../../assets/images/round-plus.png" alt=""></button>
      </div>
    </div>
  </div>
  <div class="select-ticket">
    <span class="terms">
      <input
        type="checkbox"
        (click)="toggleCheckbox($event)">
      <h3>Eu li e concordo com os termos de uso</h3>
    </span>
    <a href="#" class="see-regulation">Ver Regulamento</a>
    <button class="ensure"
      (click)="triggerOpenPixDialog($event)">
      <span>Comprar!</span>
    </button>
  </div>
</div>
