import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';

@Component({
  selector: 'app-main-home-card',
  templateUrl: './main-home-card.component.html',
  styleUrls: ['./main-home-card.component.scss']
})
export class MainHomeCardComponent implements OnInit{

  @Output() clickToDraw: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() whatsAppGroup: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Output() telegramGroup: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();

  @Input() data: any;
  @Input() index: any;
  @Input() backgroundImage: any;
  @Input() cardRightTitle: string = '';
  @Input() cardRightSubtitle: string = '';

  statusDraw: string = '';
  drawDate: Date = new Date;
  differenceTime: number = 0;
  restingTime: string = '';

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.drawDate = new Date(this.data?.dataSorteio);
    setInterval(() => {
      let difference = this.drawDate.getTime() - new Date().getTime();
      this.differenceTime = difference;
      this.restingTime = this.formatTime(this.differenceTime);
    }, 1000);
  }

  triggerDraw(action: any) {
    this.clickToDraw.emit(new TableActionEmitted(action, this.data, this.index));
  }

  triggerFinalizedDraw(action: any) {
    this.router.navigate([`/sorteio-finalizado/${this.data.id}`]);
  }

  triggerWhatsAppGroup(action: any) {
    this.whatsAppGroup.emit(new TableActionEmitted(action, this.data, this.index));
  }

  triggerTelegramGroup(action: any) {
    this.whatsAppGroup.emit(new TableActionEmitted(action, this.data, this.index));
  }

  goToLegalTerms() {
    this.router.navigate(['/termos-legais']);
  }

  private formatTime(differenceTime: number): string {
    let seconds = Math.floor((differenceTime / 1000) % 60);
    let minuts = Math.floor((differenceTime / (1000 * 60)) % 60);
    let hours = Math.floor((differenceTime / (1000 * 60 * 60)) % 24);
    let days = Math.floor((differenceTime / (1000 * 60 *60 * 24)));
    return `${days.toString().padStart(2, '0')}d ${hours.toString().padStart(2, '0')}h ${minuts.toString().padStart(2, '0')}min ${seconds.toString().padStart(2, '0')}s`;
  }

}
