import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DataService } from 'src/app/core/services/data-service/data.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit{

  loginForm: FormGroup = this.fb.group({});

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dataService: DataService,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.startForm();
  }

  ngOnInit(): void {

  }

  submitLogin() {
    let payload = {...this.loginForm.value};
    this.authService.login(payload).subscribe({
      next: this.treatLoginSuccessfull,
      error: this.treatLoginErrorAtAll
    });
  }

  backToInitialPage(data?: any) {
    this.dialogRef.close(data);
  }

  private startForm(): void {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required]],
      cpfCnpj: [null, [Validators.required]],
      idEmpresa: [this.dataService.getEnterpriseId()]
    });
  }

  private treatLoginSuccessfull = (res: any): void => {
    if (res.data) {
      this.authService.saveUserAccessData({token: res.data});
      this.router.navigate([`/`]);
      location.reload();
      this.dialogRef.close();
    }
  }

  private treatLoginErrorAtAll = (err: any): void => {
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }

}
