import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';

import { ToastService } from 'src/app/core/services/toast/toast.service';
import { LoginDialogComponent } from 'src/app/pages/login-dialog/login-dialog.component';
import { TableActionEmitted } from '../../../core/models/table-action-emitted.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Output() clickToDraw: EventEmitter<TableActionEmitted> = new EventEmitter<TableActionEmitted>();
  @Input() data: any;
  @Input() index: any;
  @Input() logoImage: any;

  @Input() isLogged: boolean = true;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private toastService: ToastService,
  ) { }

  handleUserClick() {
    if (this.authService.isAuthenticated()){
      this.router.navigate(['/user-settings']);
    } else {
      this.openLoginDialog();
    }
  }

  goToAllDraws() {
    this.router.navigate([`/todos-sorteios`]);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  goToFAQ() {
    this.router.navigate(['/faq']);
  }

  openLoginDialog(data?: any) {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      data: data,
      disableClose: true,
      maxWidth: '70vh',
      maxHeight: '70vh',
      minWidth: '50vh',
      minHeight: '50vh',
      panelClass: []
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => this.treatCloseDialog(res),
    });
  }

  private treatCloseDialog = (res: any): void => {}

}
