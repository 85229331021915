<div class="container">
  <div class="card">
    <div class="row go-back-container">
      <h1 class="title">{{ cardTitle }}</h1>
      <button class="go-back"
        (click)="backToDraw()">Voltar</button>
    </div>

    <form [formGroup]="checkoutDataForm">
      <div class="row">
        <div class="input-wrapper">
          <label>Nome Completo</label>
          <input
            type="text"
            formControlName="nome"
            (change)="updateFormToCheckoutPage()"/>
        </div>
        <!-- <div class="input-wrapper">
          <label>Sobrenome</label>
          <input
            type="text"
            formControlName="sobrenome"
            (change)="updateFormToCheckoutPage()"/>
        </div> -->
      </div>

      <div class="row">
        <div class="input-wrapper">
          <label>Número de Telefone</label>
          <input
            type="tel"
            formControlName="telefone"
            mask="(00) 00000-0000"
            (change)="updateFormToCheckoutPage()"/>
        </div>
        <div class="input-wrapper">
          <label>CPF</label>
          <input
            type="text"
            formControlName="cpf"
            mask="000.000.000-00"
            (change)="updateFormToCheckoutPage()"/>
        </div>
      </div>

      <div class="row">
        <div class="input-wrapper">
          <label>E-mail</label>
          <input
            type="email"
            formControlName="email"
            (change)="updateFormToCheckoutPage()"/>
        </div>
        <div class="input-wrapper">
          <label>Data de Nascimento</label>
          <input
            type="text"
            formControlName="dataNascimento"
            mask="00/00/0000"
            (change)="onChange($event)"/>
        </div>
      </div>
      <!-- <div class="row quantity">
        <span>Preço Und.:</span>
        <span class="quantity-value tabular">R${{ unitPrice.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</span>
      </div> -->
      <div class="row price-by-und">
        <h3>Quantidade Avulsa:</h3>
        <h3 class="price-value tabular">{{ freeTicket }} x R${{ unitPrice.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h3>
      </div>
      <div class="row price-by-und" *ngIf="packagesToShow.length > 0">
        <h3>Pacotes:</h3>
      </div>
      <div class="package-container">
        <div class="pkg-info row" *ngFor="let item of packagesToShow; let i = index">
          <h4>+{{ item.bilhetes }} Und. </h4>
          <h4>R${{ item.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}) }} x {{ item.quantidade }} = R${{ item.valor * item.quantidade }}</h4>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div>
        <div class="row price-by-und">
          <h3>Quantidade Total:</h3>
          <span class="price-value tabular">{{ quantity }} x</span>
        </div>
        <div class="row price-total">
          <h3>Total:</h3>
          <h3 class="price tabular">R$ {{ total.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</h3>
        </div>
      </div>
    </form>
  </div>
  <img [src]="image" alt="">
</div>
