import { Component } from '@angular/core';

@Component({
  selector: 'app-how-work-card',
  templateUrl: './how-work-card.component.html',
  styleUrls: ['./how-work-card.component.scss']
})
export class HowWorkCardComponent {

}
