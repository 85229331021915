import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnterpriseEndpointMapper } from '../../mappers/enterprise-endpoint.mapper';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private http: HttpClient) { }

  getTicketsByEnterprise(enterpriseId: string, drawId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Draw}/${drawId}/bilhete-premiado`, options);
  }

  getWinners(enterpriseId: string, drawId: string): Observable<any> {
    const headers = new HttpHeaders({'empresa': enterpriseId});
    const options = {headers: headers};
    return this.http.get(`${EnterpriseEndpointMapper.Draw}/${drawId}/ganhadores`, options);
  }
}
