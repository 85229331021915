import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/services/data-service/data.service';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit{

  enterpriseData: any;
  questionnaries: any[] = [];
  loadingQuestionnaries: boolean = false;

  constructor(
    private enterpriseService: EnterpriseService,
    private dataService: DataService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.enterpriseData = this.dataService.getEnterpriseData();
    this.getQuestionnaires();
  }

  private getQuestionnaires() {
    this.loadingQuestionnaries = true;
    const enterpriseId = this.enterpriseData.id;
    this.enterpriseService.getQuestionnaires(enterpriseId).subscribe({
      next: this.getQuestionnairesSuccessfull,
      error: this.faqErrorAtAll
    });
  }

  private getQuestionnairesSuccessfull = (res: any): void => {
    res.sort((a: any, b: any) => a.posicao - b.posicao);
    this.questionnaries = res;
    this.loadingQuestionnaries = false;
  }

  private faqErrorAtAll = (err: any): void => {
    this.loadingQuestionnaries = false;
    this.toastService.showToast(this.parseError(err), 'warning');
  }

  private parseError(err: any): string {
    return err?.error?.errors[0] ?
      err.error.errors[0] : 'Ocorreu um erro, tente novamente!';
  }
}
