<div class="footer">
  <div class="center-card">
    <div class="footer-group">
      <div class="social">
        <div class="social-column">
          <div class="row" *ngIf="instagram !== ''">
            <img class="image" src="../../../../assets/images/instagram-icon-mini.png" alt="Instagram-image">
            <h2>{{ instagram }}</h2>
          </div>
          <div class="row" *ngIf="kwai !== ''">
            <img class="image" src="../../../../assets/images/kwai.png" alt="Kwai-image">
            <h2>{{ kwai }}</h2>
          </div>
          <div class="row" *ngIf="facebook !== ''">
            <img class="image" src="../../../../assets/images/facebook.png" alt="Facebook-image">
            <h2>{{ facebook }}</h2>
          </div>
          <div class="row" *ngIf="tiktok !== ''">
            <img class="image" src="../../../../assets/images/tiktok.png" alt="Tiktok-image">
            <h2>{{ tiktok }}</h2>
          </div>
          <div class="row phone" *ngIf="telefoneContato !== ''">     
            <img class="image" src="../../../../assets/images/ph_phone.png" alt="Phone-image">
            <h2>{{ telefoneContato }}</h2>
            <img class="image-mini"
              src="../../../../assets/images/bx_copy.png"
              alt="Copy-image"
              (click)="copyPhoneNumber()">
          </div>
        </div>
      </div>
      <div class="topics">
        <h2 style="cursor: pointer;" (click)="triggerHome($event)">Home</h2>
        <h3 (click)="triggerDraw($event)">Sorteios</h3>
        <!-- <h3 (click)="triggerProfile($event)">Meu Perfil</h3> -->
        <h3 (click)="triggerContacts($event)">FAQ</h3>
      </div>
    </div>
    <div class="copy-group">
      <span>© 2024. Todos os direitos reservados.</span>
      <div class="privacy">
        <span (click)="goToPrivacy()">Privacidade</span>
        <span (click)="goToLegalTerms()">Termos e Condições legais</span>
      </div>
    </div>
  </div>
</div>
