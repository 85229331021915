<div class="container">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <h2>Detalhes do Sorteio</h2>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <div class="description-draw" [innerHTML]="description"></div>
  </mat-expansion-panel>
  <div class="navbar">
    <a class="money-bag" href="#">
      <img src="../../../../assets/images/money-bag.png" alt="">
      Cotas Premiadas
    </a>
    <a href="#">Achou e Ganhou na Hora!!</a>
  </div>
  <div class="overflow">
    <div class="table">
      <div class="thead">
        <div class="content item">
          <span style="width: 48px;"></span>
          <span >N° da Cota</span>
        </div>
        <span class="item">Status</span>
        <span class="item">Usuário Ganhador</span>
        <span class="item">Payout</span>
      </div>
      <div *ngFor="let item of data; let i = index"
      [ngClass]="i % 2 === 0 ? 'green-row tbody' : 'tbody'">
        <div class="content item">
          <div style="display: flex; align-items: center;"><img src="../../../../assets/images/star.png" alt="" /></div>
          <span class="payout item">#{{ item?.numeroBilhete }}</span>
          <!-- <span class="change-color item">#{{ item?.numeroBilhete }}</span> -->
        </div>
        <span [ngClass]="item?.sorteado === false ? 'status item' : 'status-payied item'">
          <img src="../../../../assets/images/coins.png" alt="" />
          {{ item?.sorteado === false ? 'Pendente' : 'Resgatado'}}
        </span>
        <span class="payout item">{{ item?.sorteado === false ? '---------------' : item?.nomeGanhador}}</span>
        <span class="payout item">+{{
          item?.premio.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL"
          })
        }}</span>
      </div>
    </div>
  </div>
</div>
